import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@material-ui/core/InputAdornment';
import { IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Redirect } from 'react-router';
import { useFormRules, useInputValidation } from '../../hooks/form-rules';
import { useBlockingLoader } from '../../hooks/loader-hooks';
import { useAlerts } from '../../hooks/alerts-hooks';
import { useAuth } from '../../AuthCtx';
import { useFormStylesUp } from './form-styles-signup';
import ConfirmationCodeVerification from './ConfirmationCodeVerification';

export default function ForgotPasswordForm() {
  const { t } = useTranslation();
  const formClasses = useFormStylesUp();

  const [ step, setStep ] = useState(0);

  const { forgotPassword, forgotPasswordSubmit, deliveryDetails, doesUserExist, isDoctor, isCorporate } = useAuth();

  const { showBlockingLoader, hideBlockingLoader } = useBlockingLoader('forgot-password-form');
  const { alertError, alertSuccess } = useAlerts();

  const { emailRule, passwordRules } = useFormRules();

  const [ emailError, emailHelperText, validateEmail ] = useInputValidation(
    [ emailRule ],
  );

  const [ passwordError, passwordHelperText, validatePassword ] = useInputValidation(
    passwordRules,
  );

  const [ repeatPasswordError, repeatPasswordHelperText, validateRepeatPassword ] = useInputValidation(
    passwordRules,
  );

  const [ passwordVisibility, setPasswordVisibility ] = useState(false);
  const [ repeatPasswordVisibility, setRepeatPasswordVisibility ] = useState(false);

  const [ passwordD, setPasswordD ] = useState('');
  const [ passwordR, setPasswordR ] = useState('');

  const togglePasswordVisibility = () => {
    return setPasswordVisibility((prev) => {
      return !prev;
    });
  };

  const toggleRepeatPasswordVisibility = () => {
    return setRepeatPasswordVisibility((prev) => {
      return !prev;
    });
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const {
      email,
    } = Object.fromEntries(formData);

    const formIsValid = ![
      validateEmail(email),
    ].some((item) => {
      return !item;
    });

    if (!formIsValid) {
      return;
    }

    const doesExist = await doesUserExist(isDoctor ? 'doctors' : isCorporate ? 'businesses' : 'customers', email);

    if (!doesExist) {
      alertError(t('Το email σας δε βρέθηκε στη βάση'));
      return;
    }

    // eslint-disable-next-line no-unreachable
    Promise.resolve()
      .then(() => {
        showBlockingLoader();
      })
      .then(() => {
        return forgotPassword({ username: email });
      })
      .then(() => {
        setStep((s) => { return s + 1; });
      })
      .catch((err) => {
        alertError(err);
      })
      .finally(() => {
        hideBlockingLoader();
      });
  };

  const handleVerify = (theCode) => {
    Promise.resolve()
      .then(() => {
        showBlockingLoader();
      })
      .then(() => {
        return forgotPasswordSubmit({ code: theCode, newPassword: passwordD });
      })
      .catch((err) => {
        alertError(err);
        throw err;
      })
      .then(() => {
        alertSuccess(t('Your password has changed'));
      })
      .then(() => {
        setStep((s) => { return s + 1; });
      })
      .catch(() => {

      })
      .finally(() => {
        hideBlockingLoader();
      });
  };

  if (0 === step) {
    return (
      <Container className={ formClasses.container }>
        <div className={ formClasses.paper }>
          <Typography component='div' variant='h5' color='secondary'>
            {t('passwordRecovery')}
          </Typography>
          <form
            noValidate
            className={ formClasses.form }
            onSubmit={ (e) => {
              return handleForgotPassword(e);
            } }
          >
            <Grid container spacing={ 3 }>
              <Grid item xs={ 12 }>
                <TextField
                  size='small'
                  variant='outlined'
                  required
                  color='secondary'
                  fullWidth
                  label='Email'
                  name='email'
                  type='email'
                  autoComplete='email'
                  error={ emailError }
                  helperText={ t(emailHelperText) }
                  onChange={ (e) => {
                    return validateEmail(e.target.value);
                  } }
                  onBlur={ (e) => {
                    return validateEmail(e.target.value);
                  } }
                />
              </Grid>
              <Grid item xs={ 12 }>
                <Button
                  fullWidth
                  type='submit'
                  variant='outlined'
                  color='secondary'
                  className={ formClasses.submit }
                >
                  {t('next')}
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    );
  }

  if (1 === step) {
    return (
      <Container className={ formClasses.container }>
        <div className={ formClasses.paper }>
          <form
            noValidate
            className={ formClasses.form }
            onSubmit={ (e) => {
              e.preventDefault();
              setStep(2);
            } }
          >
            <Grid item xs={ 12 }>
              <TextField
                size='small'
                error={ passwordError }
                helperText={ t(passwordHelperText) }
                onChange={ (e) => {
                  setPasswordD(e.target.value);
                  return validatePassword(e.target.value);
                } }
                onBlur={ (e) => {
                  setPasswordD(e.target.value);
                  return validatePassword(e.target.value);
                } }
                variant='outlined'
                required
                fullWidth
                label={ t('Νέος κωδικός') }
                name='password'
                color='secondary'
                type={ passwordVisibility ? 'text' : 'password' }
                autoComplete='new-password'
                InputProps={ {
                  endAdornment: (
                    <InputAdornment position='start'>
                      <IconButton onClick={ togglePasswordVisibility }>
                        {!passwordVisibility ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                } }
              />
            </Grid>

            <Grid item xs={ 12 } style={ { marginTop: '15px' } }>
              <TextField
                size='small'
                error={ repeatPasswordError || passwordD !== passwordR }
                helperText={ passwordD !== passwordR ? t('Οι κωδικοί πρέπει να είναι ίδιοι') : t(repeatPasswordHelperText) }
                onChange={ (e) => {
                  setPasswordR(e.target.value);
                  return validateRepeatPassword(e.target.value);
                } }
                onBlur={ (e) => {
                  setPasswordR(e.target.value);
                  return validateRepeatPassword(e.target.value);
                } }
                variant='outlined'
                required
                fullWidth
                name='password'
                color='secondary'
                label={ t('Επανάληψη κωδικού') }
                type={ repeatPasswordVisibility ? 'text' : 'password' }
                autoComplete='new-password'
                InputProps={ {
                  endAdornment: (
                    <InputAdornment position='start'>
                      <IconButton onClick={ toggleRepeatPasswordVisibility }>
                        {!repeatPasswordVisibility ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                } }
              />
            </Grid>

            <Grid item xs={ 12 }>
              <Button
                fullWidth
                type='submit'
                variant='outlined'
                color='secondary'
                className={ formClasses.submit }
              >
                {t('next')}
              </Button>
            </Grid>
          </form>

        </div>
      </Container>
    );
  }

  if (2 === step) {
    const {
      CodeDeliveryDetails: {
        Destination,
      },
    } = deliveryDetails;

    const subtitle = `${ t('Σας έχει σταλεί ο κωδικός στο') }: ${ Destination }`;

    return (
      <Container className={ formClasses.container }>
        <div className={ formClasses.paper }>
          <Typography component='div' variant='h5' color='secondary'>
            {t('Confirm change password')}
          </Typography>
          <Grid className={ formClasses.description }>
            <Typography align='left' component='div' variant='body2' color='textSecondary'>
              {subtitle}
            </Typography>
          </Grid>
          <Grid container spacing={ 2 }>
            <ConfirmationCodeVerification onVerify={ (theCode) => {
              return handleVerify(theCode);
            } }
            />
          </Grid>

        </div>
      </Container>
    );
  }

  return <Redirect to='/login' />;
}
