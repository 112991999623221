import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import clsx from 'clsx';
import { useAuth } from 'AuthCtx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { Typography, StepButton, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { corporateTheme } from 'theme/palette';
import { useNavigation } from '../../NavigationProvider';

const useStyles = makeStyles((theme) => {
  return {
    rootRoot: {
      '@media (max-width:1280px) and (min-width: 1171px)': {
        paddingTop: '164px',
        position: 'relative',
      },
    },
    root: {
      width: '100%',
      position: 'static',

      '@media (max-width:1280px) and (min-width: 1171px)': {
        width: '115%',
        position: 'absolute',
        left: '53%',
        top: 0,
        transform: 'translateX(-50%)',
      },
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    desktopStepper: {
      '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
        fontSize: '0.8rem',
      },
    },
    stepper: {
      '& .MuiMobileStepper-progress': {
        width: '100%',
        height: '15px',
        borderRadius: '15px',
      },
      '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: '#93C221',
      },
      '& .MuiPaper-root': {
        width: '100%',
      },
      '& .MuiLinearProgress-root': {
        width: '100%',
      },
      width: '100% !important',
    },
    pgxStepper: {
      '& .MuiMobileStepper-progress': {
        width: '100%',
        height: '15px',
        borderRadius: '15px',
      },
      '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: '#0286C0',
      },
      '& .MuiPaper-root': {
        width: '100%',
      },
      '& .MuiLinearProgress-root': {
        width: '100%',
      },
      width: '100% !important',
    },
    doctorStepper: {
      '& .MuiMobileStepper-progress': {
        width: '100%',
        height: '15px',
        borderRadius: '15px',
      },
      '& .MuiLinearProgress-colorPrimary': {
        backgroundColor: '#d0d3d6',
      },
      '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: '#648DAE',
      },
      '& .MuiPaper-root': {
        width: '100%',
      },
      '& .MuiLinearProgress-root': {
        width: '100%',
      },
      width: '100% !important',
    },
    corporateStepper: {
      '& .MuiMobileStepper-progress': {
        width: '100%',
        height: '15px',
        borderRadius: '15px',
      },
      '& .MuiLinearProgress-colorPrimary': {
        backgroundColor: '#d0d3d6',
      },
      '& .MuiLinearProgress-barColorPrimary': {
        backgroundColor: '#A8324C',
      },
      '& .MuiPaper-root': {
        width: '100%',
      },
      '& .MuiLinearProgress-root': {
        width: '100%',
      },
      width: '100% !important',
    },
  };
});

const size = 80;

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: size / 2,
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 135deg, #102c41, #56c1b1)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 135deg, #102c41, #56c1b1)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#d7d7d7',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: size,
    height: size,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage: 'linear-gradient( 135deg, #102c41, #56c1b1)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    background: '#93c221',
  },
  doctorCompleted: {
    background: '#648DAE',
  },
  corporateCompleted: {
    background: corporateTheme.primary.main,
  },
  padding: {
    padding: '8px',
    marginTop: '15px',

    '@media (max-width:1280px)': {
      padding: '0px',
      marginTop: '23px',
    },
  },
});

const useColorlibStepIconStylesPGX = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: size,
    height: size,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage: 'linear-gradient( 135deg, #102c41, #56c1b1)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    background: '#0286C0',
  },
  doctorCompleted: {
    background: '#648DAE',
  },
  corporateCompleted: {
    background: corporateTheme.primary.main,
  },
  padding: {
    padding: '8px',
    marginTop: '15px',

    '@media (max-width:1280px)': {
      padding: '0px',
      marginTop: '23px',
    },
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed, icon: stepNumber } = props;
  const { isDoctor, isCorporate } = useAuth();

  return (
    <div
      className={
        isDoctor
          ? clsx(classes.root, classes.padding, {
            [classes.active]: active,
            [classes.completed]: completed,
            [classes.doctorCompleted]: completed,
          })
          : isCorporate
            ? clsx(classes.root, classes.padding, {
              [classes.active]: active,
              [classes.completed]: completed,
              [classes.corporateCompleted]: completed,
            })
            : clsx(classes.root, classes.padding, {
              [classes.active]: active,
              [classes.completed]: completed,
            })
      }
    >

      <Typography variant='h4'>
        {stepNumber}
      </Typography>
    </div>
  );
}

function ColorlibStepIconPGX(props) {
  const classes = useColorlibStepIconStylesPGX();
  const { active, completed, icon: stepNumber } = props;
  const { isDoctor, isCorporate } = useAuth();

  return (
    <div
      className={
        isDoctor
          ? clsx(classes.root, classes.padding, {
            [classes.active]: active,
            [classes.completed]: completed,
            [classes.doctorCompleted]: completed,
          })
          : isCorporate
            ? clsx(classes.root, classes.padding, {
              [classes.active]: active,
              [classes.completed]: completed,
              [classes.corporateCompleted]: completed,
            })
            : clsx(classes.root, classes.padding, {
              [classes.active]: active,
              [classes.completed]: completed,
            })
      }
    >
      <Typography variant='h4'>
        {stepNumber}
      </Typography>
    </div>
  );
}

export default function KitRegistrationSteps(
  {
    steps,
    isKitPGX = false,
  },
) {
  const classes = useStyles();

  const { activePageId, navigateToPage } = useNavigation();
  const stepIdx = steps.findIndex((s) => { return s.id === activePageId; });
  const { t } = useTranslation();
  const { isDoctor, isCorporate } = useAuth();

  const isMdDown = useMediaQuery('(max-width:1170px)');

  return (
    <div className={ classes.rootRoot }>
      <div className={ classes.root }>
        {
          isMdDown ? (
            <div>
              <div style={ { fontStyle: 'bold', fontSize: 'min(1.8em, 6vw)' } }>
                {t(steps[stepIdx].label)}
              </div>
              <MobileStepper
                variant='progress'
                steps={ steps.length }
                position='static'
                activeStep={ stepIdx }
                className={ isDoctor ? classes.doctorStepper : isCorporate ? classes.corporateStepper : isKitPGX ? classes.pgxStepper : classes.stepper }
                nextButton={ null }
                backButton={ null }
              />
            </div>
          ) : (
            <Stepper
              style={ { backgroundColor: 'unset', padding: '24px 0 24px 0' } }
              alternativeLabel
              activeStep={ stepIdx }
              connector={ <ColorlibConnector /> }
              className={ classes.desktopStepper }
            >
              {steps.map(({ label, id }) => {
                return (
                  <Step
                    key={ label }
                  >
                    {
                      null === id && (
                        <StepButton
                          disabled
                          style={ { padding: 0 } }
                        >
                          <StepLabel StepIconComponent={ isKitPGX ? ColorlibStepIconPGX : ColorlibStepIcon }>
                            {t(label)}
                          </StepLabel>
                        </StepButton>
                      )
                    }
                    {
                      null !== id && (
                        <StepButton
                          style={ { padding: 0 } }
                          onClick={ () => {
                            if (null != id) {
                              navigateToPage({ pageId: id });
                            }
                          } }
                        >
                          <StepLabel
                            StepIconComponent={ isKitPGX ? ColorlibStepIconPGX : ColorlibStepIcon }
                            style={ { fontSize: '0.8rem' } }
                          >
                            {t(label)}
                          </StepLabel>
                        </StepButton>
                      )
                    }
                  </Step>
                );
              })}
            </Stepper>
          )
        }
      </div>
    </div>
  );
}
