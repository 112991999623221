import React, { Suspense, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ErrorBoundary from 'components/ErrorBoundary';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-http-backend';
import './index.css';
import dotenv from 'dotenv';
import { QueryClient, QueryClientProvider } from 'react-query';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import { ReactQueryDevtools } from 'react-query/devtools';
import { CircularProgress } from '@material-ui/core';
// import { I18nextProvider } from 'react-i18next';
import { LanguageDetector, backendOptions } from './i18n';
import App from './App';
import { AppProvider } from './AppCtx';
import { Debug } from './components/Debug';
import { AuthProvider } from './AuthCtx';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { LoaderProvider } from './LoaderCtx';
import { InformationDialogProvider } from './DialogCtx';
import { AlertsProvider } from './AlertsCtx';
import AppThemeProvider from './theme/AppThemeProvider';
import { ConfirmationDialogProvider } from './hooks/confirmation-dialog-hooks';
import Playground, { PlaygroundMode } from './Playground';

dotenv.config();

export const Loader = () => {
  return (
    <div style={ { height: '100vh', width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' } }>
      <CircularProgress style={ { color: '#93C221' } } />
    </div>
  );
};

const Root = () => {
  // Create a client
  const queryClient = new QueryClient();

  const [ translationsLoaded, setTranslationsLoaded ] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const lng = params.get('lng');

    if ('en' === lng) {
      i18n
        .use(initReactI18next) // passes i18n down to react-i18next
        .use(backend)
        .init({
          backend: backendOptions,
          fallbackLng: 'en-US',
          lng: 'en-US',
          debug: false,
          // ns: [ 'translations' ],
          // defaultNS: 'translations',
          keySeparator: '.',
          interpolation: {
            escapeValue: false,
            formatSeparator: ',',
          },
          react: {
            wait: true,
            useSuspense: false,
          },
        }, () => {
          setTranslationsLoaded(true);
        });
    } else if ('el' === lng) {
      i18n
        .use(initReactI18next) // passes i18n down to react-i18next
        .use(backend)
        .init({
          backend: backendOptions,
          fallbackLng: 'en-US',
          lng: 'el-GR',
          debug: false,
          // ns: [ 'translations' ],
          // defaultNS: 'translations',
          keySeparator: '.',
          interpolation: {
            escapeValue: false,
            formatSeparator: ',',
          },
          react: {
            wait: true,
            useSuspense: false,
          },
        }, () => {
          setTranslationsLoaded(true);
        });
    } else {
      i18n
        .use(LanguageDetector)
        .use(initReactI18next) // passes i18n down to react-i18next
        .use(backend)
        .init({
          backend: backendOptions,
          fallbackLng: 'en-US',
          debug: false,
          // ns: [ 'translations' ],
          // defaultNS: 'translations',
          keySeparator: '.',
          interpolation: {
            escapeValue: false,
            formatSeparator: ',',
          },
          react: {
            wait: true,
            useSuspense: false,
          },
        }, () => {
          setTranslationsLoaded(true);
        });
    }
  }, []);

  if (!translationsLoaded) {
    return <Loader />;
  }

  return (
    <>
      <Suspense fallback={ <Loader /> }>
        <AppProvider>
          {/* <I18nextProvider i18n={ i18n }> */}
          <QueryClientProvider client={ queryClient }>
            <AuthProvider>
              <AppThemeProvider>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <LoaderProvider>
                  <ConfirmationDialogProvider>
                    <InformationDialogProvider>
                      <AlertsProvider>
                        <Paper id='App' elevation={ 0 }>
                          <ErrorBoundary>
                            <App />
                          </ErrorBoundary>
                        </Paper>
                      </AlertsProvider>
                    </InformationDialogProvider>
                  </ConfirmationDialogProvider>
                </LoaderProvider>
                <Debug />
                { /* <SessionTimer/> */}
              </AppThemeProvider>
            </AuthProvider>
            <ReactQueryDevtools initialIsOpen={ false } />
          </QueryClientProvider>
          {/* </I18nextProvider> */}
        </AppProvider>
      </Suspense>
    </>
  );
};

ReactDOM.render(PlaygroundMode ? <Playground /> : <Root />, document.getElementById('root'));

