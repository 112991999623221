/* eslint-disable prefer-const */
import React from 'react';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import useAPI from 'hooks/api-hooks';
import { corporateTheme } from 'theme/palette';
import SimpleInputField from '../atoms/SimpleInputField';
import { useFormRules, useInputValidation } from '../../../hooks/form-rules';
import FormSection from '../atoms/FormSection';
import FormNames from '../atoms/form-names';
import { useAuth } from '../../../AuthCtx';

const CorporateDetailsForm = () => {
  const { t } = useTranslation();

  const { client } = useAPI();

  const { userAttributes, userAttributesLoaded, databaseUser } = useAuth(client);

  const brandName = databaseUser?.brand_name;

  const {
    email: initialEmail,
    brand_name: initialBrandName,
  } = userAttributes || {};

  const {
    requiredFieldRule,
  } = useFormRules();

  const [
    errorBrandName,
    helperTextBrandName,
    validateBrandName,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  if (!userAttributesLoaded) {
    return null;
  }

  return (
    <form
      noValidate
      style={ {
        padding: '24px',
      } }
    >
      <FormSection label={ t('personalDetails') } color={ corporateTheme.primary.main }>
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 } md={ 6 }>
            <SimpleInputField
              label={ t('brandName') }
              upperCase
              defaultValue={ brandName ?? initialBrandName }
              autoComplete='brand-name'
              error={ errorBrandName }
              helperText={ helperTextBrandName }
              validate={ validateBrandName }
              disabled
              name={ FormNames.brandName }
              required
            />
          </Grid>

          <Grid item xs={ 12 } md={ 6 }>
            <SimpleInputField
              name={ FormNames.email }
              label={ t('email') }
              required
              defaultValue={ initialEmail }
              readOnly
            />
          </Grid>
        </Grid>
      </FormSection>

    </form>
  );
};

CorporateDetailsForm.propTypes = {
};

CorporateDetailsForm.defaultProps = {
};

export default CorporateDetailsForm;
