import React, { useRef, useEffect, useMemo } from 'react';
import { Chart, registerables } from 'chart.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useItems } from 'models/atoms/items-hook';
import CollectionKeys from 'models/atoms/collection-keys';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useTranslation } from 'react-i18next';

Chart.register(...registerables, ChartDataLabels);

const PieChart = (props) => {
  const { i18n, t } = useTranslation();

  const chartRef = useRef(null);
  let chartInstance = null;

  const {
    items: kits,
  } = useItems(CollectionKeys.DoctorSortedKits,
    { filterQuery: {
      _limit: -1,
      created_at_gte: new Date(props.startDate).toISOString(),
      created_at_lte: new Date(props.endDate).toISOString(),
    } });

  const filteredKits = useMemo(() => {
    return kits?.filter((kit) => {
      return kit.business?.brand_name;
    });
  }, [ kits, props.startDate, props.endDate ]);

  const cardioKits = useMemo(() => {
    return filteredKits?.filter((kit) => {
      return ('IDNA Cardio Health' === kit.thematic_package.thematic_category_id.name
        || 'Cardio Health' === kit.thematic_package.thematic_category_id.name);
    }).map((kit) => {
      return kit.serial_number_id.serial;
    });
  }, [ filteredKits ]);

  const pgxKits = useMemo(() => {
    return filteredKits?.filter((kit) => {
      return 'PGx' === kit.thematic_package.thematic_category_id.name;
    }).map((kit) => {
      return kit.serial_number_id.serial;
    });
  }, [ filteredKits ]);

  const nutrigenetixKits = useMemo(() => {
    return filteredKits?.filter((kit) => {
      return ('NutriGenetix' === kit.thematic_package.thematic_category_id.name)
         || ('NutriGenetix-Laboratories' === kit.thematic_package.thematic_category_id.name);
    }).map((kit) => {
      return kit.serial_number_id.serial;
    });
  }, [ filteredKits ]);

  useEffect(() => {
    if (chartRef.current) {
      chartInstance = new Chart(chartRef.current, {
        type: 'pie',
        data: {
          labels: [ 'NutriGenetix', 'Cardio', 'PGx' ],
          datasets: [
            {
              data: [ nutrigenetixKits?.length, cardioKits?.length, pgxKits?.length ],
              backgroundColor: [ '#A02B93', '#C00000', '#0070C0' ],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'bottom',
              labels: {
                boxWidth: 10,
                boxHeight: 10,
                usePointStyle: true,
                pointStyle: 'rect',
              },
            },
            datalabels: {
              display: true,
              color: '#fff',
              font: {
                weight: 'bold',
                size: 16,
              },
              formatter: (value) => { return value; },
            },
            title: {
              display: true,
              text: t('All Lifechecks'),
              font: {
                size: 15,
                weight: '400',
              },
              padding: {
                bottom: 20,
              },
              color: '#333',
            },
          },
        },
      });
    }

    return () => {
      if (chartInstance) { chartInstance.destroy(); }
    };
  }, [ props.startDate, props.endDate, nutrigenetixKits, cardioKits, pgxKits, i18n.language ]);

  if (!filteredKits) {
    return (
      <div style={ { display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '300px',
        minHeight: '200px' } }
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <canvas ref={ chartRef } />
  );
};

export default PieChart;
