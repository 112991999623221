import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useAuth, UserGroups } from 'AuthCtx';
import { AppCtx } from 'AppCtx';
import { useAlerts } from 'hooks/alerts-hooks';
import SimpleInputField from '../atoms/SimpleInputField';
import { useFormRules, useInputValidation } from '../../../hooks/form-rules';
import FormSection from '../atoms/FormSection';
import FormSectionSubmit from '../atoms/FormSectionSubmit';
import { useSerialNumberRegistration } from '../../../models/kits';

const SerialNumberRegistrationForm = (
  {
    onSubmit,
    onError,
    registrationType = null,
    prevSerial = null,
  },
) => {
  const { t } = useTranslation();

  const { setShowPGxPopup } = React.useContext(AppCtx);
  const { alertError } = useAlerts();

  const [ _serialNumber, setSerialNumber ] = useState('');

  const [ isSubmitting, setSubmitting ] = useState(false);
  const [ childLoading, setChildLoading ] = useState(false);

  const { mutateAsync: registerKit } = useSerialNumberRegistration();

  const { requiredFieldRule } = useFormRules();

  const { userGroup } = useAuth();

  const [
    errorSerialNumber,
    helperTextSerialNumber,
    validateSerialNumber,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const handleSubmit = async (e) => {
    setSubmitting(true);
    e.preventDefault();
    const formData = new FormData(e.target);
    const {
      serialNumber,
    } = Object.fromEntries(formData);

    // greedy validation
    // don't validate all of them immediately

    if (![
      () => { return validateSerialNumber(serialNumber); },
    ].every((f) => { return true === f(); })) {
      setSubmitting(false);
      return null;
    }

    if (false === /^[A-Z]+\d+$/.test(serialNumber)
        && 'bi-test' !== serialNumber && 'ch-test' !== serialNumber && 'id-test' !== serialNumber
        && 'ng-test' !== serialNumber && 'nu-test' !== serialNumber && 'sp-test' !== serialNumber
        && 'vi-test' !== serialNumber && 'wl-test' !== serialNumber) {
      alertError(t('Το serial πρέπει να αποτελείται μόνο απo κεφαλαίους λατινικούς χαρακτήρες και αριθμούς'));
      setSubmitting(false);
      return null;
    }

    return registerKit({ serialNumber, registrationType, isChildrenRegistration: false, ancestorId: prevSerial })
      .then((data) => {
        if (userGroup !== UserGroups.Doctor && userGroup !== UserGroups.Corporate && 'iDNA PGx kit' === data.thematic_package.name) {
          setShowPGxPopup(true);
        }

        return onSubmit(data);
      })
      .catch(onError)
      .finally(() => {
        setSubmitting(false);
      });
  };

  const registerKitForChild = () => {
    setChildLoading(true);
    const serialNumber = _serialNumber;

    // greedy validation
    // don't validate all of them immediately

    if (![
      () => { return validateSerialNumber(serialNumber); },
    ].every((f) => { return true === f(); })) {
      setSubmitting(false);
      return null;
    }

    return registerKit({ serialNumber, registrationType, isChildrenRegistration: true })
      .then((data) => {
        if (userGroup !== UserGroups.Doctor && 'iDNA PGx kit' === data.thematic_package.name) {
          setShowPGxPopup(true);
        }

        return onSubmit(data);
      })
      .catch(onError)
      .finally(() => {
        setChildLoading(false);
      });
  };

  return (
    <form
      noValidate
      onSubmit={ handleSubmit }
      style={ {
        padding: '24px',
      } }
    >
      <FormSection
        label={ null != prevSerial ? 'Αναβάθμιση kit' : t('newKitRegistration') }
        color={ userGroup === UserGroups.Doctor ? '#526D9C' : '' }
      >
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 }>
            <Grid container spacing={ 1 }>
              <Grid item xs={ 12 }>
                <SimpleInputField
                  label={ t('serialNumber') }
                  name='serialNumber'
                  error={ errorSerialNumber }
                  helperText={ helperTextSerialNumber }
                  setExternalValue={ setSerialNumber }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormSection>

      <FormSectionSubmit
        color={ userGroup === UserGroups.Doctor ? '#526D9C' : '' }
        label={ userGroup === UserGroups.Doctor || userGroup === UserGroups.Corporate
          ? t('registerKit') : t('Εγγραφή για εσάς') }
        loading={ isSubmitting }
        registerKitForChild={ userGroup === UserGroups.Corporate ? null : registerKitForChild }
        childLoading={ childLoading }
      />
    </form>
  );
};

SerialNumberRegistrationForm.propTypes = {
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
};

SerialNumberRegistrationForm.defaultProps = {
  onSubmit: () => { return null; },
  onError: () => { return null; },
};

export default SerialNumberRegistrationForm;
