import { Grid, Avatar, Icon, Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQueries from 'hooks/media-query-hooks';
import { useTranslation } from 'react-i18next';
import CardBase from '../app/CardBase';
import SectionAlternate from '../app/DoctorSectionAlternate';
import { NavigationProvider } from '../../NavigationProvider';
import dnaBg from '../../assets/dna_bg.png';
import Menu from './atoms/CorporateMenu';
import Pages from './atoms/Pages';
import useCorporateNavigationPages from './corporate-dashboard/navigationPages';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: '100%',
      width: '100%',
    },
    section: {
      '& .section-alternate__content': {
        paddingTop: 0,
        marginTop: theme.spacing(-5),
        position: 'relative',
        zIndex: 1,
        marginLeft: '0',
      },
      '& .card-base__content': {
        padding: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(2),
        },
      },
      backgroundColor: 'transparent',
    },
    menu: {
      minHeight: '60vh',
      boxShadow: '0px 24px 80px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '16px',
      background: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'space-between',
      justifyContent: 'space-between',
      height: '100%',
    },
    helpContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      border: '2px solid #A8324C',
      borderRadius: '15px',
      padding: '10px 0',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
  };
});

export const Help = ({ style = {} }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={ classes.helpContainer } style={ { ...style } }>
      <Avatar style={ { background: '#F4F4F4' } }>
        <Icon style={ { height: '100%', width: '100%' } }>
          <img
            src='telemarketer-support-svgrepo-com.svg'
            alt=''
            style={ { height: '45px', width: '45px', color: '#666666' } }
          />
        </Icon>
      </Avatar>

      <Typography variant='h6' style={ { marginLeft: '24px' } }>
        {t('needHelp')}
        <br />
        {t('click')}
        {' '}
        <a
          href='https://idnagenomics.com/contact-page/'
          style={ { color: 'inherit', fontWeight: 'bold' } }
          target='_blank'
          rel='noreferrer'
        >
          {t('here')}
        </a>
      </Typography>

    </div>
  );
};

const CorporateDashboard = () => {
  const classes = useStyles();

  const theme = useTheme();

  const navigationPages = useCorporateNavigationPages();
  const { isSm } = useMediaQueries();

  return (
    <NavigationProvider
      pageKey='pid'
      fallbackPageId='general'
      cardioSerial={ undefined }
      _threeToOneSerial={ undefined }
      _nutritionSerial={ undefined }
      _vitaminsSerial={ undefined }
      _sportsSerial={ undefined }
      _bioAgeSerial={ undefined }
      _pgxSerial={ undefined }
      _nutrigenetixSerial={ undefined }
    >
      <div className={ classes.root }>
        <div style={
          {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0.3,
          }
        }
        >
          <img alt='dna' src={ dnaBg } width='auto' height='100%' />
        </div>
        <div style={ { position: 'relative', width: '100%', minHeight: '100px', backgroundColor: theme.palette.primary.main } } />
        <SectionAlternate className={ classes.section }>
          <Grid container spacing={ 4 } alignItems='flex-start'>
            <Grid
              item
              xs={ 12 }
              md={ 4 }
              lg={ 3 }
              style={ { display: isSm ? 'none' : '' } }
            >
              <CardBase
                withShadow
                align='left'
                className={ classes.menu }
                cardContentProps={ { style: { minHeight: '60vh',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between' } } }
              >
                <Menu pages={ navigationPages } />
                <Help />
              </CardBase>
            </Grid>
            <Grid item xs={ 12 } md={ 8 } lg={ 9 }>
              <Pages pages={ navigationPages } />
            </Grid>
          </Grid>
        </SectionAlternate>
      </div>
    </NavigationProvider>
  );
};

export default CorporateDashboard;
