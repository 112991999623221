import React, { useEffect, useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { Divider, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import FormSection from '../atoms/FormSection';
import SimpleCheckbox from '../atoms/SimpleCheckbox';
import { useInputValidation } from '../../../hooks/form-rules';
import FormSectionSubmit from '../atoms/FormSectionSubmit';
import SimpleRadioInlineRequired from '../atoms/SimpleRadioInlineRequired';
import { generalConsents, pgxConsents } from '../../../models/agreements';
import { useKit } from '../../../models/kits';

const GeneralConsentPGXForm = (
  {
    kitId,
    onSubmit,
    onError,
    href: _href,
    goToPrevPage,
  },
) => {
  const { t, i18n } = useTranslation();

  const { isLoading, upsertAgreement, kitAgreements, child } = useKit(kitId);

  const [ initialConsentsValues, setInitialConsentsValues ] = useState({
    [pgxConsents.PGX_GENERAL_CONSENT]: null,
    [generalConsents.ANONYMOUS_DNA_RESEARCH_PURPOSE_CONSENT]: null,
    [pgxConsents.PHARMACOGENETIC_ANALYSIS_CONSENT]: null,
    [generalConsents.LEGAL_GUARDIAN_CONSENT]: null,
  });

  const gdprRef = useRef(null);
  const dataRef = useRef(null);
  const firstRadioRef = useRef(null);

  const setConsentValue = (consentName, consentValue) => {
    if (Object.keys(initialConsentsValues).includes(consentName)) {
      setInitialConsentsValues((prevValues) => {
        return {
          ...prevValues,
          [consentName]: consentValue,
        };
      });
    }
  };

  useEffect(() => {
    if (!isLoading && kitAgreements) {
      setInitialConsentsValues((prevValues) => {
        const updatedAgreements = Object.keys(initialConsentsValues)
          .reduce((acc, consentName) => {
            return {
              ...acc,
              [consentName]: 'boolean' === typeof kitAgreements[consentName] ? kitAgreements[consentName] : null,
            };
          }, {});
        if (_.isEqual(prevValues, updatedAgreements)) {
          return prevValues;
        }
        return updatedAgreements;
      });
    }
  }, [ isLoading, kitAgreements ]);

  const [ isSubmitting, setSubmitting ] = useState(false);

  const [ agreeError, agreeHelperText, validateAgree ] = useInputValidation(
    [ (v) => { return 'yes' === v || t('reg_kit.consents.common.required_consent'); } ],
  );

  const [ errorDNA, helperTextDNA, validateDNA ] = useInputValidation(
    [ (v) => { return [ 'yes', 'no' ].includes(v) || t('reg_kit.consents.common.required_selection'); } ],
  );

  const [ agreeErrorGDPR, agreeHelperTextGDPR, validateAgreeGDPR ] = useInputValidation(
    [ (v) => { return !!v || t('reg_kit.consents.common.required_consent'); } ],
  );

  const [ parentConsentError, parentConsentHelperText, validateParentConsent ] = useInputValidation(
    [ (v) => { return 'yes' === v || 'reg_kit.consents.common.required_consent'; } ],
  );

  const handleSubmit = (e) => {
    setSubmitting(true);
    e.preventDefault();
    const formData = new FormData(e.target);
    const {
      [pgxConsents.PGX_GENERAL_CONSENT]: pgxGeneralConsent,
      [generalConsents.ANONYMOUS_DNA_RESEARCH_PURPOSE_CONSENT]: anonymousDNAResearchPurposeConsent,
      [pgxConsents.PHARMACOGENETIC_ANALYSIS_CONSENT]: gdprConsent,
      [generalConsents.LEGAL_GUARDIAN_CONSENT]: legalGuardianConsent,
    } = Object.fromEntries(formData);

    // greedy validation
    // don't validate all of them immediately

    if (![
      () => {
        const value = validateAgree(pgxGeneralConsent);
        if (!value) {
          gdprRef.current.scrollIntoView({ block: 'center' });
        }
        return value;
      },
      () => {
        return validateAgreeGDPR(gdprConsent);
      },
      () => {
        const value = validateDNA(anonymousDNAResearchPurposeConsent);
        if (!value) {
          firstRadioRef.current.scrollIntoView({ block: 'center' });
        }
        return value;
      },
      () => {
        if (null != child) {
          return validateParentConsent(legalGuardianConsent);
        }

        return true;
      },
    ].every((f) => {
      return true === f();
    })) {
      setSubmitting(false);
      return onError();
    }

    return Promise.resolve()
      .then(() => {
        const upsertAgreements = [
          {
            consentName: pgxConsents.PGX_GENERAL_CONSENT,
            status: 'yes' === pgxGeneralConsent,
          },
          {
            consentName: generalConsents.ANONYMOUS_DNA_RESEARCH_PURPOSE_CONSENT,
            status: 'yes' === anonymousDNAResearchPurposeConsent,
          },
          {
            consentName: pgxConsents.PHARMACOGENETIC_ANALYSIS_CONSENT,
            status: 'yes' === gdprConsent,
          },
          {
            consentName: generalConsents.LEGAL_GUARDIAN_CONSENT,
            status: 'yes' === legalGuardianConsent,
          },
        ].map((d) => {
          return Promise.resolve().then(() => {
            return upsertAgreement(d);
          });
        });
        return Promise.all(upsertAgreements);
      })
      .then((data) => {
        return onSubmit(data);
      })
      .catch(onError)
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (isLoading) {
    return <p>Loading</p>;
  }

  return (
    <form
      noValidate
      onSubmit={ handleSubmit }
    >
      <Grid
        container
        style={ {
          padding: '24px',
        } }
      >
        <FormSection label={ t('reg_kit.consents.common.general_consent_title') }>
          <Grid container spacing={ 3 }>
            {
              [
                'informed_analysis_purpose',
                'proceed_analysis',
                'repeat_analysis',
                'dispatch_doctor',
                'real_info',
              ].map((fact) => {
                return (
                  <Grid key={ fact } item>
                    <Typography variant='body2' style={ { textAlign: 'initial' } }>
                      {t(`reg_kit.consents.pgx.${ fact }`)}
                    </Typography>
                  </Grid>
                );
              })
            }
            <Grid item ref={ gdprRef } style={ { marginLeft: 'auto' } }>
              <SimpleCheckbox
                required
                label={ t('common.agree') }
                name={ pgxConsents.PGX_GENERAL_CONSENT }
                value={ initialConsentsValues[pgxConsents.PGX_GENERAL_CONSENT] }
                onChange={ (v) => { return setConsentValue(pgxConsents.PGX_GENERAL_CONSENT, v); } }
                error={ agreeError }
                helperText={ agreeHelperText }
              />
            </Grid>
          </Grid>

          <Grid container spacing={ 3 }>
            <Grid item xs={ 12 }>
              <Typography
                color='secondary'
                variant='h6'
                style={ { width: '100%' } }
              >
                {t('reg_kit.consents.common.consent_personal_data_title')}
              </Typography>
              <Divider style={ { width: '66%' } } />
            </Grid>

            {
              'en-US' === i18n.language && (
                <Grid item xs={ 12 }>
                  <Typography
                    variant='body1'
                    style={ { marginLeft: '0', width: '100%' } }
                  >
                    {t('reg_kit.consents.non_doctor.consent_to_following')}
                  </Typography>
                </Grid>
              )
            }

            <Grid item lg={ 9 } xs={ 12 }>
              <ul style={ { marginTop: '0', marginBottom: '8px' } }>
                <li>
                  <Typography variant='body2' style={ { textAlign: 'initial' } }>
                    {t('reg_kit.consents.adult.pgx.process_personal_data')}
                  </Typography>
                </li>
              </ul>
            </Grid>
            <Grid
              item
              ref={ dataRef }
              lg={ 3 }
              xs={ 12 }
              style={ { display: 'flex', alignItems: 'center', justifyContent: 'flex-end' } }
            >
              <SimpleCheckbox
                formStyle={ { display: 'flex', justifyContent: 'center' } }
                required
                label={ t('common.agree') }
                name={ pgxConsents.PHARMACOGENETIC_ANALYSIS_CONSENT }
                value={ initialConsentsValues[pgxConsents.PHARMACOGENETIC_ANALYSIS_CONSENT] }
                onChange={ (v) => {
                  return setConsentValue(pgxConsents.PHARMACOGENETIC_ANALYSIS_CONSENT, v);
                } }
                error={ agreeErrorGDPR }
                helperText={ agreeHelperTextGDPR }
              />
            </Grid>
          </Grid>
          <Typography style={ { fontStyle: 'italic', marginLeft: '40px' } } variant='body2'>
            {t('reg_kit.consents.common.unable_to_process_warning')}
          </Typography>

          <Grid item container spacing={ 3 } alignItems='center' style={ { marginTop: '10px' } }>
            <Grid item xs={ 12 } lg={ 9 }>
              <ul style={ { marginTop: '0' } }>
                <li>
                  <Typography variant='body2' style={ { textAlign: 'initial' } }>
                    {t('reg_kit.consents.adult.non_bioage.anonymized_data_consent')}
                    {' *'}
                  </Typography>
                </li>
              </ul>
            </Grid>
            <Grid
              item
              xs={ 12 }
              lg={ 3 }
              ref={ firstRadioRef }
              style={ { display: 'flex', justifyContent: 'flex-end' } }
            >
              <SimpleRadioInlineRequired
                required
                label=''
                name={ generalConsents.ANONYMOUS_DNA_RESEARCH_PURPOSE_CONSENT }
                defaultValue={ null !== initialConsentsValues[generalConsents.ANONYMOUS_DNA_RESEARCH_PURPOSE_CONSENT]
                  ? (initialConsentsValues[generalConsents.ANONYMOUS_DNA_RESEARCH_PURPOSE_CONSENT] ? 'yes' : 'no') : null }
                options={ [
                  {
                    label: t('common.yes'),
                    value: 'yes',
                  },
                  {
                    label: t('common.no'),
                    value: 'no',
                  },
                ] }
                error={ errorDNA }
                helperText={ helperTextDNA }
              />
            </Grid>
          </Grid>
        </FormSection>
      </Grid>
      <Grid
        container
        style={ {
          padding: '24px',
        } }
      >
        {/* <FormSection label={ t('gdpr') }>
          <Grid container spacing={ 2 }>
            <Grid item>
              <Typography variant='body2' style={ { textAlign: 'initial' } }>
                {t('gdprPrompt')}
              </Typography>
              <a
                href={ href }
                target='_blank'
                rel='noreferrer'
              >
                {t('readHere')}
              </a>
            </Grid>
            <Grid item container spacing={ 2 }>
              <Grid item>
                <SimpleCheckbox
                  required
                  label={ t('I have read and understood the above') }
                  name={ generalConsents.GDPR_CONSENT }
                  value={ initialConsentsValues[generalConsents.GDPR_CONSENT] }
                  onChange={ (v) => { return setConsentValue(generalConsents.GDPR_CONSENT, v); } }
                  error={ agreeErrorGDPR }
                  helperText={ agreeHelperTextGDPR }
                />
              </Grid>
            </Grid>
          </Grid>
        </FormSection> */}
      </Grid>
      { (null != child) && (
        <Grid
          item
          container
          spacing={ 2 }
          style={ { display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'column' } }
        >
          <Grid item xs={ 12 }>
            <SimpleCheckbox
              label={ t('reg_kit.consents.child.guardian_consent') }
              name={ generalConsents.LEGAL_GUARDIAN_CONSENT }
              value={ initialConsentsValues[generalConsents.LEGAL_GUARDIAN_CONSENT] }
              onChange={ (v) => {
                setConsentValue(generalConsents.LEGAL_GUARDIAN_CONSENT, v);

                if (null !== child) {
                  validateParentConsent(true === v ? 'yes' : 'no');
                }
              } }
              required={ null !== child }
              error={ parentConsentError }
              helperText={ parentConsentHelperText }
            />
          </Grid>
        </Grid>
      ) }
      <FormSectionSubmit
        goToPrevPage={ goToPrevPage }
        label={ t('common.save') }
        loading={ isSubmitting }
      />
    </form>
  );
};

GeneralConsentPGXForm.propTypes = {
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
};

GeneralConsentPGXForm.defaultProps = {
  onSubmit: () => { return null; },
  onError: () => { return null; },
};

export default GeneralConsentPGXForm;
/*
<Grid item container spacing={ 2 }>
              <Grid item>
                <SimpleCheckbox
                  label={ t('Συγκατάθεση ως γονέας ή κηδεμόνας') }
                  name={ generalConsents.LEGAL_GUARDIAN_CONSENT }
                  value={ initialConsentsValues[generalConsents.LEGAL_GUARDIAN_CONSENT] }
                  onChange={ (v) => { return setConsentValue(generalConsents.LEGAL_GUARDIAN_CONSENT, v); } }
                />
              </Grid>
            </Grid>
*/
