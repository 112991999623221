/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.


const usersConfig = {

  // REQUIRED - Amazon Cognito Region
  region: 'eu-central-1',

  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: 'eu-central-1_dD78IsUyl',

  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: '21vaflk1pbbtice1ird4ld4e4h',

  // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  mandatorySignIn: false,

  // OPTIONAL - Configuration for cookie storage
  // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
  cookieStorage: {
    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    domain: 'myemr.idnagenomics.com',
    // OPTIONAL - Cookie path
    path: '/',
    // OPTIONAL - Cookie expiration in days
    expires: 365,
    // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    sameSite: 'strict',
    // OPTIONAL - Cookie secure flag
    // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    secure: true,
  },

  // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
  authenticationFlowType: 'USER_PASSWORD_AUTH',

  // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
  clientMetadata: {},

  // OPTIONAL - Hosted UI configuration
  oauth: {},
};

const doctorsConfig = {

  // REQUIRED - Amazon Cognito Region
  region: 'eu-central-1',

  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: 'eu-central-1_1AanAQRfY',

  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: 'hhap7b2kd64hbusq06hrtlrce',

  // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  mandatorySignIn: false,

  // OPTIONAL - Configuration for cookie storage
  // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
  cookieStorage: {
    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    domain: 'myemr.idnagenomics.com',
    // OPTIONAL - Cookie path
    path: '/',
    // OPTIONAL - Cookie expiration in days
    expires: 365,
    // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    sameSite: 'strict',
    // OPTIONAL - Cookie secure flag
    // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    secure: true,
  },

  // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
  authenticationFlowType: 'USER_PASSWORD_AUTH',

  // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
  clientMetadata: {},

  // OPTIONAL - Hosted UI configuration
  oauth: {},
};

const corporateConfig = {

  // REQUIRED - Amazon Cognito Region
  region: 'eu-central-1',

  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: 'eu-central-1_8dekLoEQL',

  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: '2643hk04psviqk5umkcos082qj',

  // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  mandatorySignIn: false,

  // OPTIONAL - Configuration for cookie storage
  // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
  cookieStorage: {
    // REQUIRED - Cookie domain (only required if cookieStorage is provided)
    // domain: 'test-myemr.cassandra.wings-ict-solutions.eu',
    domain: 'myemr.idnagenomics.com',
    // OPTIONAL - Cookie path
    path: '/',
    // OPTIONAL - Cookie expiration in days
    expires: 365,
    // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
    sameSite: 'strict',
    // OPTIONAL - Cookie secure flag
    // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
    secure: true,
  },

  // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
  authenticationFlowType: 'USER_PASSWORD_AUTH',

  // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
  clientMetadata: {},

  // OPTIONAL - Hosted UI configuration
  oauth: {},
};

const cognitoPools = {
  usersConfig,
  doctorsConfig,
  corporateConfig
}

export default cognitoPools;

