/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import { Container, Typography } from '@material-ui/core';
import useAPI from 'hooks/api-hooks';
import LimitedQuestionnaire from 'components/layout/LimitedQuestionnaire';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import CollectionKeys from 'models/atoms/collection-keys';
import { useAuth } from '../../../AuthCtx';
import { useFormRules, useInputValidation } from '../../../hooks/form-rules';
import FormNames from '../atoms/form-names';
import { useKit } from '../../../models/kits';
import { InvitationReason, useKitInvitation } from '../../../models/invitations';
import FormSection from '../atoms/FormSection';
import SimpleInputField from '../atoms/SimpleInputField';
import SimpleDateField from '../atoms/SimpleDateField';
import SimpleRadio from '../atoms/SimpleRadio';
import SimplePhoneField from '../atoms/SimplePhoneField';
import FormSectionSubmit from '../atoms/FormSectionSubmit';
import DoctorSearchForm from '../common-forms/DoctorSearchForm';
import SpecialtySelectField from '../atoms/SpecialtySelectField';
import CountrySelectField, { countryNameToCode } from '../atoms/CountrySelectField';

const UserDetailsWithHealthcareProfessional = (
  {
    kitId,
    onSubmit,
    onError,
    goToPrevPage,
  },
) => {
  const { t } = useTranslation();

  const { client } = useAPI();

  const [ isSubmitting, setSubmitting ] = useState(false);
  const { isLoading, kitHealthcareProfessionalInvitations, isKitPGX, isKitWellness, isKitBioAge, data, customer } = useKit(kitId);
  const { createInvitation } = useKitInvitation({ kitId, reasonOfInvitation: InvitationReason.HEALTHCARE_PROFESSIONAL_SHARE_RESULTS });

  const initialFirstName = customer?.name ?? '';
  const initialLastName = customer?.family_name ?? '';
  const initialEmail = customer?.email;
  const initialDateOfBirth = customer?.birthdate ?? '';
  const initialGender = customer?.gender ?? '';

  const initialMobileNumber = customer?.phone_number;
  const initialHouseNumber = customer?.house_number;

  let jsonAddress = {};
  try {
    jsonAddress = JSON.parse(customer?.address || JSON.stringify({}));
  } catch (e) {
    if (null != customer?.address.postalCode && null != customer?.address.streetAddress && null != customer?.address.city) {
      jsonAddress = { streetAddress: customer?.address.streetAddress,
        postalCode: customer?.address.postalCode,
        city: customer?.address.city,
        streetNumber: customer?.address.street_number };
    }
  }

  const initialStreetAddress = jsonAddress.streetAddress ?? customer?.streetAddress ?? '';
  const initialStreetNumber = jsonAddress.street_number ?? customer?.street_number ?? '';
  const initialPostalCode = jsonAddress.postalCode ?? customer?.postalCode ?? '';
  const initialCity = jsonAddress.city ?? customer?.city ?? '';

  const initialCountry = customer?.country ?? 'GR';

  const [ wellnessQuestionnaire, setWellnessQuestionnaire ] = useState({});

  const [ wellnessQuestionnaireErrorID, setWellnessQuestionnaireErrorID ] = useState(-1);

  const [ doctorData, setDoctorData ] = useState(null);

  const [ referralDoctor, setreferralDoctor ] = useState(null);

  useEffect(() => {
    if (data?.referral_doctor) {
      setreferralDoctor(data.referral_doctor);
    }
  }, [ data?.referral_doctor ]);

  const [ tCountry, setTCountry ] = useState(initialCountry);

  const [ externalFirstName, setExternalFirstName ] = useState(doctorData?.name);
  const [ externalLastName, setExternalLastName ] = useState(doctorData?.family_name);
  const [ externalCompanyName, setExternalCompanyName ] = useState(doctorData?.company_name);
  const [ externalEmail, setExternalEmail ] = useState(doctorData?.email);
  const [ externalSpecialty, setExternalSpecialty ] = useState(doctorData?.specialty);
  const [ externalBusinessNumber, setExternalBusinessNumber ] = useState(doctorData?.business_number);
  const [ externalPhoneNumber, setExternalPhoneNumber ] = useState(doctorData?.phone_number);

  const hasSelectedADoc = useMemo(() => {
    return ('' !== externalFirstName && null != externalFirstName)
    || ('' !== externalLastName && null != externalLastName)
    || ('' !== externalCompanyName && null != externalCompanyName)
    || ('' !== externalEmail && null != externalEmail)
    || ('' !== externalSpecialty && null != externalSpecialty)
    || ('' !== externalBusinessNumber && null != externalBusinessNumber)
    || ('' !== externalPhoneNumber && null != externalPhoneNumber);
  }, [ externalFirstName, externalLastName, externalCompanyName,
    externalSpecialty, externalBusinessNumber, externalPhoneNumber, externalEmail ]);

  useEffect(() => {
    setTCountry(initialCountry);
  }, [ initialCountry ]);

  const handleDoctorData = (localData) => {
    setDoctorData(localData);
  };

  const handlereferralDoctorData = (localData) => {
    setreferralDoctor(localData);
  };

  const {
    requiredFieldRule,
    phoneNumberRule,
    emailRule,
    atMost10Rule,
    dateRule,
    notAdultRule,
  } = useFormRules();

  const [
    errorStreetAddress,
    helperTextStreetAddress,
    validateStreetAddress,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorPostalCode,
    helperTextPostalCode,
    validatePostalCode,
  ] = useInputValidation(
    [
      requiredFieldRule,
      atMost10Rule,
    ],
  );

  const [
    errorCity,
    helperTextCity,
    validateCity,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorCountry,
    helperTextCountry,
    validateCountry,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorFirstName,
    helperTextFirstName,
    validateFirstName,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorLastName,
    helperTextLastName,
    validateLastName,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorBirthdate,
    helperTextBirthdate,
    validateBirthdate,
  ] = useInputValidation(
    [
      requiredFieldRule,
      dateRule,
    ],
  );

  const [
    errorGender,
    helperTextGender,
    validateGender,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorMobileNumber,
    helperTextMobileNumber,
    validateMobileNumber,
  ] = useInputValidation(
    [
      (v) => { return requiredFieldRule(v?.phoneNumber); },
      (v) => { return requiredFieldRule(v?.country); },
      phoneNumberRule,
    ],
  );

  const [
    errorHouseNumber,
    helperTextHouseNumber,
    validateHouseNumber,
  ] = useInputValidation(
    [
      phoneNumberRule,
    ],
  );

  const [
    errorFirstNameHP,
    helperTextFirstNameHP,
    validateFirstNameHP,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorLastNameHP,
    helperTextLastNameHP,
    validateLastNameHP,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorProfessionHP,
    helperTextProfessionHP,
    validateProfessionHP,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorEmail,
    helperTextEmail,
    validateEmail,
  ] = useInputValidation(
    [
      requiredFieldRule,
      emailRule,
    ],
  );

  const [
    errorEmailHP,
    helperTextEmailHP,
    validateEmailHP,
  ] = useInputValidation(
    [
      requiredFieldRule,
      emailRule,
    ],
  );

  const [
    errorMobileNumberHP,
    helperTextMobileNumberHP,
    validateMobileNumberHP,
  ] = useInputValidation(
    [
      phoneNumberRule,
    ],
  );

  const [
    errorBusinessNumberHP,
    helperTextBusinessNumberHP,
    validateBusinessNumberHP,
  ] = useInputValidation(
    [
      (v) => { return requiredFieldRule(v?.phoneNumber); },
      (v) => { return requiredFieldRule(v?.country); },
      phoneNumberRule,
    ],
  );

  const handleSubmit = (e, withInvite) => {
    setSubmitting(true);

    e?.preventDefault();

    const formData = new FormData(e.target);

    let {
      [FormNames.email]: email,
      [FormNames.firstName]: firstName,
      [FormNames.lastName]: lastName,
      [FormNames.gender]: gender,
      [FormNames.dateOfBirth]: dateOfBirth,
      [FormNames.streetAddress]: streetAddress,
      [FormNames.postalCode]: postalCode,
      [FormNames.city]: city,
      [FormNames.country]: country,
      [FormNames.mobileNumber]: mobileNumber,
      [FormNames.countryMobileNumber]: countryMobileNumber,
      [FormNames.houseNumber]: houseNumber,
      [FormNames.countryHouseNumber]: countryHouseNumber,
      [`${ FormNames.firstName }HP`]: firstNameHP,
      [`${ FormNames.lastName }HP`]: lastNameHP,
      [`${ FormNames.profession }HP`]: professionHP,
      [`${ FormNames.email }HP`]: emailHP,
      [`${ FormNames.mobileNumber }HP`]: mobileNumberHP,
      [`${ FormNames.countryMobileNumber }HP`]: countryMobileNumberHP,
      [`${ FormNames.businessNumber }HP`]: businessNumberHP,
      [`${ FormNames.countryBusinessNumber }HP`]: countryBusinessNumberHP,
      [`${ FormNames.brandName }HP`]: brandNameHP,
    } = Object.fromEntries(formData);

    country = countryNameToCode(country);

    // greedy validation
    // don't validate all of them immediately

    const userHasNotFillThisForm = [
      firstNameHP,
      lastNameHP,
      professionHP,
      emailHP,
      mobileNumberHP,
      businessNumberHP,
    ].every((field) => {
      return !field;
    });

    if (![
      () => {
        const value = validateFirstName(firstName);

        if (!value) {
          window.scrollTo({ top: 0 });
        }

        return value;
      },

      () => {
        const value = validateLastName(lastName);

        if (!value) {
          window.scrollTo({ top: 0 });
        }

        return value;
      },

      () => {
        const value = validateBirthdate(dateOfBirth);

        if (!value) {
          window.scrollTo({ top: 0 });
        }

        return value;
      },

      () => {
        const value = validateGender(gender);

        if (!value) {
          window.scrollTo({ top: 0 });
        }

        return value;
      },

      () => { return validateStreetAddress(streetAddress); },
      () => { return validatePostalCode(postalCode); },
      () => { return validateCity(city); },
      () => { return validateCountry(country); },
      () => { return validateEmail(email); },
      () => {
        return validateMobileNumber(
          {
            phoneNumber: mobileNumber,
            country: countryMobileNumber,
          },
        );
      },
      () => {
        return validateHouseNumber(
          {
            phoneNumber: houseNumber,
            country: countryHouseNumber,
          },
        );
      },
      () => { return !withInvite || userHasNotFillThisForm || validateFirstNameHP(firstNameHP); },
      () => { return !withInvite || userHasNotFillThisForm || validateLastNameHP(lastNameHP); },
      () => { return !withInvite || userHasNotFillThisForm || validateProfessionHP(professionHP); },
      () => { return !withInvite || userHasNotFillThisForm || validateEmailHP(emailHP); },
      () => {
        return !withInvite || userHasNotFillThisForm || validateMobileNumberHP(
          {
            phoneNumber: mobileNumberHP,
            country: countryMobileNumberHP,
          },
        );
      },
      () => {
        return !withInvite || userHasNotFillThisForm || validateBusinessNumberHP(
          {
            phoneNumber: businessNumberHP,
            country: countryBusinessNumberHP,
          },
        );
      },
    ].every((f) => {
      return true === f();
    })) {
      setSubmitting(false);
      return onError();
    }

    return Promise.resolve()
      .then(() => {
        if (false === isKitPGX) {
          client.put(`${ CollectionKeys.Kits }/${ kitId }`, {
            referral_doctor: referralDoctor?.id ? referralDoctor?.id : null,
          });
        }

        return null;
      })
      .then(() => {
        if (null === customer) {
          return client.post('/customers/doctorcreate', {
            registration_kit_id: kitId,
            name: firstName?.toUpperCase(),
            family_name: lastName?.toUpperCase(),
            email,
            gender,
            birthdate: dateOfBirth,
            country,
            address: JSON.stringify({ streetAddress: streetAddress?.toUpperCase(),
              postalCode,
              city: city?.toUpperCase() }),
            phone_number: mobileNumber,
            house_number: houseNumber,
            ...(isKitWellness) && { extra_info: wellnessQuestionnaire },
          })
            .then(() => {
              onSubmit({
                firstName,
                lastName,
                gender,
                streetAddress,
                postalCode,
                city,
                dateOfBirth,
                mobileNumber,
                houseNumber,
              });
            }).then(() => {
              setSubmitting(false);
            });
        }

        return client.put('/customers/details/doctorupdate', { registration_kit_id: kitId,
          customerId: customer?.id,
          name: firstName?.toUpperCase(),
          family_name: lastName?.toUpperCase(),
          email,
          gender,
          birthdate: dateOfBirth,
          country,
          address: JSON.stringify({ streetAddress: streetAddress?.toUpperCase(),
            postalCode,
            city: city?.toUpperCase() }),
          phone_number: mobileNumber,
          ...(isKitWellness) && { extra_info: wellnessQuestionnaire },
          house_number: houseNumber });
      })
      .then(() => {
        if (withInvite && !userHasNotFillThisForm && false === isKitPGX) {
          return createInvitation({
            thirdPartyEmail: emailHP,
            metadata: {
              firstName: firstNameHP?.toUpperCase(),
              lastName: lastNameHP?.toUpperCase(),
              specialty: professionHP,
              email: emailHP,
              mobileNumber: mobileNumberHP,
              businessNumber: businessNumberHP,
              brand_name: brandNameHP?.toUpperCase(),
            },
          });
        }
        return null;
      })
      .then(() => {
        return onSubmit();
      })
      .catch(onError)
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (isLoading) {
    return (
      <LoaderSkeleton />
    );
  }

  const alreadyInvited = kitHealthcareProfessionalInvitations && kitHealthcareProfessionalInvitations.length;

  return (
    <>
      <Container>
        <Alert
          severity='info'
          style={ {
            textAlign: 'initial',
          } }
        >
          { t('reg_kit.personal_data.doctor.non_bioage.personal_data_info') }
        </Alert>
      </Container>
      <form
        id='user-details-with-healthcare-id'
        noValidate
        onSubmit={ (e) => { return handleSubmit(e, !alreadyInvited); } }
      >
        <Grid
          container
          style={ {
            padding: '24px',
            paddingBottom: '0px',
          } }
        >
          <FormSection label={ t('reg_kit.personal_data.doctor.non_pgx.personal_data_title') }>
            <Grid container spacing={ 2 }>
              <Grid item xs={ 12 }>
                <Grid container spacing={ 2 }>
                  <Grid item xs={ 12 } md={ 6 }>
                    <SimpleInputField
                      label={ t('common.first_name') }
                      autoComplete='given-name'
                      defaultValue={ initialFirstName }
                      name={ FormNames.firstName }
                      required
                      error={ errorFirstName }
                      helperText={ helperTextFirstName }
                      validate={ validateFirstName }
                      lettersOnly
                      upperCase
                    />
                  </Grid>
                  <Grid item xs={ 12 } md={ 6 }>
                    <SimpleInputField
                      label={ t('common.last_name') }
                      defaultValue={ initialLastName }
                      autoComplete='family-name'
                      name={ FormNames.lastName }
                      required
                      error={ errorLastName }
                      lettersOnly
                      helperText={ helperTextLastName }
                      validate={ validateLastName }
                      upperCase
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={ 12 } md={ 6 }>
                <SimpleDateField
                  label={ t('common.birthdate') }
                  defaultValue={ initialDateOfBirth }
                  name={ FormNames.dateOfBirth }
                  required
                  error={ errorBirthdate }
                  helperText={ helperTextBirthdate }
                  validate={ validateBirthdate }
                />
              </Grid>
              <Grid item xs={ 12 } md={ 6 }>
                <SimpleRadio
                  label={ t('common.gender') }
                  secondaryLabel={
                    t('common.gender_explanation')
                  }
                  defaultValue={ initialGender }
                  name={ FormNames.gender }
                  required
                  options={ [
                    {
                      value: 'male',
                      label: t('common.male'),
                    },
                    {
                      value: 'female',
                      label: t('common.female'),
                    },
                  ] }
                  error={ errorGender }
                  helperText={ helperTextGender }
                  validate={ validateGender }
                />
              </Grid>

              {
                isKitWellness && (
                  <Grid item xs={ 12 }>
                    <LimitedQuestionnaire
                      kitId={ kitId }
                      wellnessQuestionnaire={ wellnessQuestionnaire }
                      setWellnessQuestionnaire={ setWellnessQuestionnaire }
                      wellnessQuestionnaireErrorID={ wellnessQuestionnaireErrorID }
                      setWellnessQuestionnaireErrorID={ setWellnessQuestionnaireErrorID }
                    />
                  </Grid>
                )
              }

            </Grid>
          </FormSection>
          <FormSection label={ t('common.postal_address') }>
            <Grid container spacing={ 2 }>
              <Grid item xs={ 12 }>
                <Grid container spacing={ 1 }>
                  <Grid item xs={ 8 }>
                    <SimpleInputField
                      label={ t('common.street_and_number') }
                      autoComplete='street-address'
                      defaultValue={ initialStreetAddress }
                      name={ FormNames.streetAddress }
                      required
                      error={ errorStreetAddress }
                      helperText={ helperTextStreetAddress }
                      validate={ validateStreetAddress }
                      upperCase
                    />
                  </Grid>

                  <Grid item xs={ 4 }>
                    <SimpleInputField
                      label={ t('common.postal_code') }
                      defaultValue={ initialPostalCode }
                      autoComplete='postal-code'
                      name={ FormNames.postalCode }
                      required
                      error={ errorPostalCode }
                      helperText={ helperTextPostalCode }
                      validate={ validatePostalCode }
                    />
                  </Grid>

                  <Grid item container spacing={ 2 }>
                    <Grid item xs={ 12 } sm={ 8 }>
                      <SimpleInputField
                        label={ t('common.city') }
                        defaultValue={ initialCity }
                        name={ FormNames.city }
                        required
                        error={ errorCity }
                        helperText={ helperTextCity }
                        validate={ validateCity }
                        upperCase
                      />
                    </Grid>

                    <Grid item xs={ 12 } sm={ 4 }>
                      <CountrySelectField
                        label={ t('common.country') }
                        defaultValue={ initialCountry }
                        name={ FormNames.country }
                        required
                        error={ errorCountry }
                        helperText={ helperTextCountry }
                        validate={ validateCountry }
                        onChange={ (val) => { setTCountry(val); } }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </FormSection>
          <FormSection label={ t('common.communication_info') }>
            <Grid container spacing={ 2 }>
              <Grid item xs={ 12 }>
                <SimpleInputField
                  name={ FormNames.email }
                  label={ t('common.email') }
                  required
                  defaultValue={ initialEmail }
                  error={ errorEmail }
                  helperText={ helperTextEmail }
                  validate={ (validateEmail) }
                />
              </Grid>
              <Grid item xs={ 12 } md={ 6 }>
                <SimplePhoneField
                  name={ FormNames.mobileNumber }
                  label={ t('common.mobile_number') }
                  defaultValue={ initialMobileNumber }
                  autoComplete='tel-national'
                  defaultCountryValue={ initialCountry ?? 'GR' }
                  countrySelectName={ FormNames.countryMobileNumber }
                  required
                  error={ errorMobileNumber }
                  helperText={ helperTextMobileNumber }
                  validate={ validateMobileNumber }
                />
              </Grid>
              <Grid item xs={ 12 } md={ 6 }>
                <SimplePhoneField
                  name={ FormNames.houseNumber }
                  defaultValue={ initialHouseNumber }
                  autoComplete='tel-national'
                  label={ t('common.home_number') }
                  defaultCountryValue={ initialCountry ?? 'GR' }
                  countrySelectName={ FormNames.countryHouseNumber }
                  error={ errorHouseNumber }
                  helperText={ helperTextHouseNumber }
                  validate={ validateHouseNumber }
                />
              </Grid>
            </Grid>
          </FormSection>
        </Grid>

        {
          false === isKitPGX && (
            <div>
              <DoctorSearchForm
                title={ t('doctors_recommendation') }
                subtitle={ t('select_referral_doctor_to_other') }
                func={ handlereferralDoctorData }
                kitId={ kitId }
                country={ tCountry }
                doctorSearchFormValue={ referralDoctor }
              />
            </div>
          )
        }

        {
          isKitPGX ? null
            : (alreadyInvited) ? (
              <>
                <Container style={ { marginTop: '12px' } }>
                  <Alert
                    severity='success'
                    style={ { textAlign: 'initial' } }
                  >
                    {t('reg_kit.personal_data.common.already_invited')}
                  </Alert>
                </Container>
                <Grid
                  container
                  style={ {
                    padding: '24px 24px 0px 24px',
                  } }
                >
                  <FormSection label={ t('common.doctor_info') }>

                    <Grid container spacing={ 2 }>
                      <Grid item xs={ 12 }>
                        <Grid container spacing={ 2 }>
                          <Grid item xs={ 12 } md={ 6 }>
                            <SimpleInputField
                              label={ t('common.first_name') }
                              defaultValue={ kitHealthcareProfessionalInvitations[0].metadata.firstName }
                              readOnly
                            />
                          </Grid>
                          <Grid item xs={ 12 } md={ 6 }>
                            <SimpleInputField
                              label={ t('common.last_name') }
                              defaultValue={ kitHealthcareProfessionalInvitations[0].metadata.lastName }
                              readOnly
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={ 6 }>
                        <SimpleInputField
                          label={ t('common.company_name') }
                          defaultValue={ kitHealthcareProfessionalInvitations[0].metadata.brand_name }
                          readOnly
                        />
                      </Grid>
                      <Grid item xs={ 6 }>
                        <SimpleInputField
                          label={ t('common.specialty') }
                          defaultValue={ kitHealthcareProfessionalInvitations[0].metadata.specialty }
                          readOnly
                        />
                      </Grid>
                      <Grid item xs={ 12 }>
                        <SimpleInputField
                          label={ t('common.email') }
                          defaultValue={ kitHealthcareProfessionalInvitations[0].metadata.email }
                          readOnly
                        />
                      </Grid>
                      <Grid item xs={ 12 } md={ 6 }>
                        <SimplePhoneField
                          label={ t('common.business_number') }
                          defaultValue={ kitHealthcareProfessionalInvitations[0].metadata.businessNumber }
                          readOnly
                        />
                      </Grid>
                      <Grid item xs={ 12 } md={ 6 }>
                        <SimplePhoneField
                          label={ t('common.mobile_number') }
                          defaultValue={ kitHealthcareProfessionalInvitations[0].metadata.mobileNumber }
                          readOnly
                        />
                      </Grid>
                    </Grid>
                  </FormSection>
                </Grid>
              </>
            ) : (
              <>
                <div style={ {} }>
                  <DoctorSearchForm
                    title={ t('send_results_report') }
                    subtitle={ t('select_receiver_doctor_to_other') }
                    func={ handleDoctorData }
                    kitId={ kitId }
                    country={ tCountry }
                  />
                </div>
                <Grid
                  container
                  style={ {
                    padding: '24px 24px 0px 24px',
                  } }
                >
                  <FormSection label={ t('common.doctor_info') }>
                    <Grid container spacing={ 2 }>
                      <Grid item xs={ 12 }>
                        <Grid container spacing={ 2 }>
                          <Grid item xs={ 12 } md={ 6 }>
                            <SimpleInputField
                              label={ t('common.first_name') }
                              autoComplete='healthcare-professional-name'
                              name={ `${ FormNames.firstName }HP` }
                              defaultValue={ doctorData?.name }
                              lettersOnly
                              required={ hasSelectedADoc }
                              error={ errorFirstNameHP }
                              helperText={ helperTextFirstNameHP }
                              validate={ validateFirstNameHP }
                              setExternalValue={ setExternalFirstName }
                              upperCase
                            />
                          </Grid>
                          <Grid item xs={ 12 } md={ 6 }>
                            <SimpleInputField
                              label={ t('common.last_name') }
                              autoComplete='healthcare-professional-family-name'
                              name={ `${ FormNames.lastName }HP` }
                              required={ hasSelectedADoc }
                              lettersOnly
                              defaultValue={ doctorData?.family_name }
                              error={ errorLastNameHP }
                              helperText={ helperTextLastNameHP }
                              validate={ validateLastNameHP }
                              setExternalValue={ setExternalLastName }
                              upperCase
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={ 12 } sm={ 6 }>
                        <SpecialtySelectField
                          label={ t('common.specialty_profession') }
                          name={ `${ FormNames.profession }HP` }
                          setExternalValue={ setExternalSpecialty }
                          required={ hasSelectedADoc }
                          isKitPGX={ isKitPGX }
                          defaultValue={ doctorData?.specialty }
                          autoComplete='healthcare-professional-profession'
                          type='text'
                          error={ errorProfessionHP }
                          helperText={ helperTextProfessionHP }
                          validate={ validateProfessionHP }
                        />
                      </Grid>
                      <Grid item xs={ 6 } sm={ 6 }>
                        <SimpleInputField
                          label={ t('common.company_name') }
                          name={ `${ FormNames.brandName }HP` }
                          defaultValue={ doctorData?.brand_name }
                          autoComplete='brand-name'
                          setExternalValue={ setExternalCompanyName }
                          upperCase
                        />
                      </Grid>
                      <Grid item xs={ 12 }>
                        <SimpleInputField
                          label={ t('common.email') }
                          name={ `${ FormNames.email }HP` }
                          required={ hasSelectedADoc }
                          defaultValue={ doctorData?.email }
                          autoComplete='healthcare-professional-email'
                          type='email'
                          error={ errorEmailHP }
                          helperText={ helperTextEmailHP }
                          validate={ validateEmailHP }
                          setExternalValue={ setExternalEmail }
                        />
                      </Grid>
                      <Grid item xs={ 12 } md={ 6 }>
                        <SimplePhoneField
                          name={ `${ FormNames.businessNumber }HP` }
                          required={ hasSelectedADoc }
                          label={ t('common.business_number') }
                          defaultCountryValue={ initialCountry ?? 'GR' }
                          countrySelectName={ `${ FormNames.countryBusinessNumber }HP` }
                          defaultValue={ doctorData?.business_number || '' }
                          error={ errorBusinessNumberHP }
                          helperText={ helperTextBusinessNumberHP }
                          validate={ validateBusinessNumberHP }
                          setExternalValue={ setExternalBusinessNumber }
                        />
                      </Grid>
                      <Grid item xs={ 12 } md={ 6 }>
                        <SimplePhoneField
                          name={ `${ FormNames.mobileNumber }HP` }
                          label={ t('common.mobile_number') }
                          defaultCountryValue={ initialCountry ?? 'GR' }
                          countrySelectName={ `${ FormNames.countryMobileNumber }HP` }
                          defaultValue={ doctorData?.phone_number || '' }
                          error={ errorMobileNumberHP }
                          helperText={ helperTextMobileNumberHP }
                          setExternalValue={ setExternalPhoneNumber }
                        />
                      </Grid>
                    </Grid>
                  </FormSection>
                </Grid>
              </>
            )
        }

        <Grid
          container
          style={ {
            padding: '0px 24px',
          } }
        >
          <FormSectionSubmit
            label={ t('common.save') }
            loading={ isSubmitting }
            goToPrevPage={ goToPrevPage }
          />
        </Grid>
      </form>
    </>
  );
};

UserDetailsWithHealthcareProfessional.propTypes = {
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
};

UserDetailsWithHealthcareProfessional.defaultProps = {
  onSubmit: () => { return null; },
  onError: () => { return null; },
};

export default UserDetailsWithHealthcareProfessional;
