import { responsiveFontSizes } from '@material-ui/core';
import createTheme from '@material-ui/core/styles/createTheme';
import { light, dark, corporateTheme } from './palette';

const getTheme = ({ themeMode, switchPrimarySecondary, isCorporate }) => {
  let palette = 'light' === themeMode ? light : dark;

  const {
    primary,
    secondary,
  } = palette;

  if (isCorporate) {
    palette = corporateTheme;
  } else if (switchPrimarySecondary) {
    palette = {
      ...palette,
      primary: secondary,
      secondary: primary,
    };
  }

  return responsiveFontSizes(
    createTheme({
      custom: {
        colors: {
          emerald: '#50c878',
          wetAsphalt: '#1a252f',
          oceanBlue: '#006994',
          seaGreen: '#2e8b57',
          pumpkin: '#ff7518',
        },
      },
      palette,
      layout: {
        topBarHeight: 80,
        contentWidth: 1236,
        drawerWidth: 128,
      },
      typography: {
        // fontFamily: '"Nunito", "Manrope"',
        fontFamily: '"Roboto"',
        button: {
          textTransform: 'capitalize',
          fontWeight: 600,
          fontSize: 16,
        },
      },
      zIndex: {
        appBar: 1200,
        drawer: 1100,
      },
      overrides: {
        MuiTypography: {
          subtitle2: {
            fontSize: [ 12, '!important' ],
          },
        },
        MuiButton: {
          root: {
            '&:focus': {
              outline: 'none',
            },
          },
        },
      },
    }),
  );
};

export default getTheme;
