import AppBar from '@material-ui/core/AppBar';
import DoneIcon from '@material-ui/icons/Done';
import { AppCtx } from 'AppCtx';
import GetAppIcon from '@material-ui/icons/GetApp';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Grid, Modal, Typography, IconButton, Dialog, DialogContentText, DialogContent } from '@material-ui/core';
import useMediaQueries from 'hooks/media-query-hooks';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import React from 'react';
import { useAuth } from '../../AuthCtx';
import MobileMenu from './atoms/MobileMenu';

const useStyles = makeStyles((theme) => {
  return {
    shop: {
      padding: '0px 19px 0px 20px',
      gap: '10px',
      borderRadius: '50px',
      height: '42px',
      width: '115px',
      color: 'white',
    },
    left: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    userNLang: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '15rem',
    },
    menu: {
      boxShadow: '0px 33.6px 112px rgba(0, 0, 0, 0.05), 0px 7.505px 25.0167px rgba(0, 0, 0, 0.0298054), 0px 2.23443px 7.44811px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '22.4px',
      display: 'flex',
      background: 'white',
      padding: '10px',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '98px',
      height: '42px',
    },
    appBar: {
      height: theme.layout.topBarHeight,
      overflow: 'hidden', // TODO: (this is a temporary solution) Remove overflow and fix the horizontal scrollbar problem
      boxShadow: 'none',
      flexDirection: 'row',
      zIndex: theme.zIndex.drawer + 1,
      padding: theme.spacing(0, 2),
      mixBlendMode: 'normal',
      backdropFilter: 'blur(40px)',
    },

    inner: {
      // maxWidth: theme.layout.contentWidth,
      // maxWidth: '88vw',
    },

    menuEshop: {
      color: '#5f5f5f',
      background: 'transparent',
      '&:hover': {
        color: 'white',
        background: '#526A9D',
      },
    },

    menuCalendar: {
      color: '#5f5f5f',
      background: 'transparent',
      '&:hover': {
        color: 'white',
        background: '#93C221',
      },
    },

    menuProfile: {
      color: '#5f5f5f',
      background: 'transparent',
      '&:hover': {
        color: 'white',
        background: '#9C9BEE',
      },
    },

    menuNotifications: {
      alignItems: 'center',
      color: '#5f5f5f',
      background: 'transparent',
      '&:hover': {
        color: 'white',
        background: '#D89BEE',
      },
    },
    menuItem: {
      height: '51px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',

      '&:hover': {
        cursor: 'pointer',
      },
    },
    popupMenu: {
      marginTop: '3px',
      width: '200px',
      background: 'white',
      boxShadow: '0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054)',
      borderRadius: '16px',
      paddingLeft: '10px',
      border: '1px solid rgba(145, 145, 145, 0.3)',
    },
    langSwitcherMobile: {
      transform: 'translate(35%)',
    },
    idnalogoMobile: {
      paddingLeft: '80px',
      '@media (max-width:875px)': {
        paddingLeft: '50px',
      },
    },
  };
});

export const NewKitRegistrationModal = ({ setShowNewRegiKitModal, justBoughtAKit, setJustBoughtAKit }) => {
  const { t } = useTranslation();
  return (
    <div
      style={ {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: 'white',
        boxShadow: '24px',
        textTransform: 'none',
        borderRadius: '15px',
        padding: '50px',
      } }
    >
      <IconButton
        style={ { color: 'red', position: 'absolute', right: '10px', top: '10px', margin: 0, padding: 0 } }
        onClick={
          () => {
            setShowNewRegiKitModal(false);
            setJustBoughtAKit(false);
          }
        }
      >
        <CloseIcon />
      </IconButton>
      <div style={ { display: 'flex', alignItems: 'center', gap: '20px' } }>
        <DoneIcon style={ { transform: 'scale(2)', color: '#93C221' } } />

        {
          justBoughtAKit ? (
            <Typography variant='h4'>
              {t('Η αγορά ολοκληρώθηκε επιτυχώς.')}
            </Typography>
          ) : (
            <Typography variant='h4'>
              {t('Επιτυχής Εγγραφή κιτ!')}
            </Typography>
          )
        }

      </div>
    </div>
  );
};

const UseAppModal = ({ setShowedUseAppNotification }) => {
  return (
    <div
      style={ {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: 'white',
        boxShadow: '24px',
        textTransform: 'none',
        borderRadius: '15px',
        padding: '50px',
        width: '90vw',
      } }
    >
      <IconButton
        style={ { color: 'red', position: 'absolute', right: '10px', top: '10px', margin: 0, padding: 0 } }
        onClick={
          () => {
            localStorage.setItem('showedUseApp', 'true');
            setShowedUseAppNotification(true);
          }
        }
      >
        <CloseIcon />
      </IconButton>
      <div style={ { display: 'flex', alignItems: 'center', gap: '20px' } }>
        <Typography variant='h4'>
          Για καλύτερη εμπειρία, κατέβασε την εφαρμογή
        </Typography>
        <GetAppIcon style={ { transform: 'scale(2)', color: '#115293' } } />
      </div>
    </div>
  );
};

const CustomAppHeader = ({ hideUserFields = false }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { isMdDown } = useMediaQueries();

  const { userIsAuthenticated } = useAuth();
  const { setShowNewRegiKitModal, showNewRegiKitModal, showedUseAppNotification, setShowedUseAppNotification,
    justBoughtAKit, setJustBoughtAKit, showPGxPopup, setShowPGxPopup } = React.useContext(AppCtx);
  const alreadySawIt = localStorage.getItem('showedUseApp') || 'false';

  // const { isSm } = useMediaQueries();

  return (
    <AppBar
      position='sticky'
      className={ classes.appBar }
      style={ { background: true === userIsAuthenticated ? 'rgba(243, 243, 243, 0.4)' : 'rgba(243, 243, 243, 0.4)' } }
    >

      <Grid
        item
        style={ { display: 'flex',
          justifyContent: 'left',
          alignItems: 'left',
          alignSelf: 'center',
          cursor: 'pointer' } }
        xs='auto'
        onClick={ () => {
          history.push('/');
        } }
      >
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <img
          alt='idna-labs logo'
          src='/idnagenomics_idnalaboratories_Logo.png'
          style={ { maxHeight: isMdDown ? '45px' : '50px' } }
          height='100%'
          width='auto'
          className={ classes.idnalogoMobile }
        />
      </Grid>

      {userIsAuthenticated && !hideUserFields && (
        <>
          <MobileMenu />

          <Modal
            open={ showNewRegiKitModal }
            onClose={ () => { setShowNewRegiKitModal(false); setJustBoughtAKit(false); } }
          >
            <NewKitRegistrationModal
              setShowNewRegiKitModal={ setShowNewRegiKitModal }
              justBoughtAKit={ justBoughtAKit }
              setJustBoughtAKit={ setJustBoughtAKit }
            />
          </Modal>

          <Dialog
            open={ false && showPGxPopup }
            onClose={ () => { setShowPGxPopup(false); } }
          >
            <div style={ { position: 'relative', paddingTop: '20px' } }>
              <IconButton
                style={ { right: '5px', top: '5px', position: 'absolute', padding: 0, margin: 0 } }
                onClick={ () => {
                  setShowPGxPopup(false);
                } }
              >
                <CloseIcon style={ { color: 'red' } } />
              </IconButton>
              <DialogContent>
                <DialogContentText>
                  <Typography>
                    {t('Κατά την online εγγραφή του iDNA PGx CNS Kit, σας δίνεται η δυνατότητα να πληρώσετε online την Φαρμακογενετική Ανάλυση με χρεωστική/πιστωτική ή προπληρωμένη κάρτα, με έως')}

                    {' '}

                    <span style={ { fontWeight: 'bold' } }>
                      {t('6 άτοκες δόσεις')}
                    </span>

                    {' '}

                    {t('(οι δόσεις αφορούν αγορές άνω των 50€).')}
                  </Typography>

                  <br />
                  <Typography>
                    {t('Σε περίπτωση που δεν επιθυμείτε την online πληρωμή, όπως συμπληρώσετε τα συνοδευτικά έγγραφα του kit και εξοφλήσετε μέσω των συνεργαζόμενων τραπεζών της iDNA Laboratories.')}
                  </Typography>
                </DialogContentText>
              </DialogContent>
            </div>
          </Dialog>

          <Modal
            open={ isMobile && false && (isAndroid || isIOS) && !showedUseAppNotification && 'false' === alreadySawIt }
            onClose={ () => {
              setShowedUseAppNotification(true);
              localStorage.setItem('showedUseApp', 'true');
            } }
          >
            <UseAppModal
              setShowedUseAppNotification={ setShowedUseAppNotification }
            />
          </Modal>
        </>

      )}
    </AppBar>
  );
};

export default CustomAppHeader;
