import React, { useRef, useEffect, useMemo } from 'react';
import { Chart, registerables } from 'chart.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useItems } from 'models/atoms/items-hook';
import CollectionKeys from 'models/atoms/collection-keys';
import { useTranslation } from 'react-i18next';

Chart.register(...registerables, ChartDataLabels);

const BarChart = (props) => {
  const { i18n, t } = useTranslation();

  const chartRef = useRef(null);
  let chartInstance = null;

  const {
    items: kits,
  } = useItems(CollectionKeys.DoctorSortedKits,
    { filterQuery: {
      _limit: -1,
      created_at_gte: new Date(props.startDate).toISOString(),
      created_at_lte: new Date(props.endDate).toISOString(),
    } });

  const filteredKits = useMemo(() => {
    return kits?.filter((kit) => {
      return kit.business?.brand_name;
    });
  }, [ kits, props.startDate, props.endDate ]);

  const groupedKits = useMemo(() => {
    const grouped = [ ];

    filteredKits?.forEach((kit) => {
      if (!kit.business?.brand_name) { return; }

      const categoryName = kit.thematic_package?.thematic_category_id?.name;
      const isCardio = 'IDNA Cardio Health' === categoryName || 'Cardio Health' === categoryName;
      const isPgx = 'PGx' === categoryName;
      const isNutriGenetix = 'NutriGenetix' === categoryName || 'NutriGenetix-Laboratories' === categoryName;

      const existingEntry = grouped.find((entry) => { return entry?.brand_name === kit.business?.brand_name; });

      if (existingEntry) {
        if (isCardio) { existingEntry.cardio += 1; }
        if (isPgx) { existingEntry.pgx += 1; }
        if (isNutriGenetix) { existingEntry.nutrigenetix += 1; }
      } else {
        grouped.push({
          brand_name: kit.business?.brand_name,
          cardio: isCardio ? 1 : 0,
          pgx: isPgx ? 1 : 0,
          nutrigenetix: isNutriGenetix ? 1 : 0,
        });
      }
    });
    return grouped;
  }, [ filteredKits ]);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.width = groupedKits.length * 80;
      chartRef.current.height = 300;

      chartInstance = new Chart(chartRef.current, {
        type: 'bar',
        data: {
          labels: groupedKits.map((kit) => { return kit.brand_name; }),
          datasets: [
            {
              label: 'NutriGenetix',
              data: groupedKits.map((kit) => { return kit.nutrigenetix; }),
              backgroundColor: '#A02B93',
            },
            {
              label: 'Cardio',
              data: groupedKits.map((kit) => { return kit.cardio; }),
              backgroundColor: '#C00000',
            },
            {
              label: 'PGx',
              data: groupedKits.map((kit) => { return kit.pgx; }),
              backgroundColor: '#0070C0',
            },
          ],
        },
        options: {
          borderColor: 'rgba(0,0,0,0)',
          borderWidth: 2,
          maintainAspectRatio: false,
          responsive: false,
          scales: {
            x: { },
            y: {
              beginAtZero: true,
            },
          },
          plugins: {
            legend: {
              position: 'bottom',
              labels: {
                boxWidth: 10,
                boxHeight: 10,
                usePointStyle: true,
                pointStyle: 'rect',
              },
            },
            datalabels: {
              display: true,
              color: '#000',
              anchor: 'end',
              align: 'top',
              font: {
                size: 12,
              },
              formatter: (value) => { return value; },
            },
            title: {
              display: true,
              text: t('lifecheckSales'),
              padding: {
                bottom: 20,
              },
              font: {
                size: 15,
                weight: '400',
              },
              align: 'start',
              color: '#333',
            },
          },
        },
      });
    }

    return () => {
      if (chartInstance) { chartInstance.destroy(); }
    };
  }, [ groupedKits, i18n.language ]);

  if (!filteredKits) {
    return (
      <div style={ { display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '300px',
        minHeight: '200px' } }
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <canvas ref={ chartRef } />
  );
};

export default BarChart;
