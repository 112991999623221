// Hook
import { useEffect, useRef, useState, useCallback } from 'react';
import { supportedTelephoneCountries } from 'components/app/CountrySelect';

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const useGetCountryFromIP = () => {
  const [ country, setCountry ] = useState(null);
  const [ loaded, setLoaded ] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch('https://api.country.is').then((resp) => {
          return resp.json();
        }).catch(() => {
          return 'GR';
        });

        let parsedCountry;

        const supportedCountry = supportedTelephoneCountries.find((s) => {
          return s === response?.country;
        });

        if (null == response || 'GR' === response) {
          parsedCountry = 'GR';
        } else if (supportedCountry === undefined) {
          parsedCountry = 'US';
        } else {
          parsedCountry = response.country;
        }

        setCountry(parsedCountry);
      } catch {
        setCountry('GR');
      } finally {
        setLoaded(true);
      }
    })();
  }, []);

  return { country, loaded };
};

// A wrapper for "JSON.parse()"" to support "undefined" value
function parseJSON(value) {
  try {
    return 'undefined' === value ? undefined : JSON.parse(value ?? '');
  } catch {
    console.error('parsing error on', { value });
    return undefined;
  }
}

export function useLocalStorage(key, initialValue, parseFunc) {
  // Get from local storage then
  // parse stored json or return initialValue
  const readValue = useCallback(() => {
    // Prevent build error "window is undefined" but keeps working
    if ('undefined' === typeof window) {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);
      // return item ? (parseJSON(item) as T) : initialValue;
      if (item) {
        if (parseFunc !== undefined) {
          return parseFunc(item);
        }
        return parseJSON(item);
      }
      return initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key “${ key }”:`, error);
      return initialValue;
    }
  }, [ initialValue, key, parseFunc ]);

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [ storedValue, setStoredValue ] = useState(readValue);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    // Prevent build error "window is undefined" but keeps working
    if ('undefined' === typeof window) {
      console.warn(`Tried setting localStorage key “${ key }” even though environment is not a client`);
    }

    try {
      // Allow value to be a function so we have the same API as useState
      const newValue = value instanceof Function ? value(storedValue) : value;

      window.localStorage.setItem(key, JSON.stringify(newValue));

      // Save state
      setStoredValue(newValue);
    } catch (error) {
      console.warn(`Error setting localStorage key “${ key }”:`, error);
    }
  };

  useEffect(() => {
    setStoredValue(readValue());
  }, []);

  return [ storedValue, setValue ];
}
