import React, { useState } from 'react';
import { makeStyles, styled } from '@material-ui/core/styles';
import axios from 'axios';
import { Grid, Typography, Button, TextField, Collapse, Dialog, DialogContent, IconButton } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { useInputValidation, useFormRules } from 'hooks/form-rules';
import { useTranslation } from 'react-i18next';
import { useAlerts } from 'hooks/alerts-hooks';
import { FifthPage } from './LandingPage';

const useStyles = makeStyles(() => {
  return {
    container: {
      backgroundColor: '#FFFFFF',
      overflow: 'hidden',
    },
    mobilescreen: {
      flexWrap: 'nowrap',
      // marginLeft: '240px',
      marginRight: '0px',
      '@media (max-width:600px)': {

        marginLeft: '5vw',
        marginRight: '5vw',
        marginTop: '100px',
      },
      '@media (max-width:1100px)': {
        flexWrap: 'wrap',
      },
      '@media (max-width:1300px) and (min-width:1101px)': {
        marginLeft: 'clamp(5px, 10vw, 240px)',
      },
      '@media (min-width:1301px)': {
        marginLeft: '240px',
      },
    },
    smalltext: {
      fontSize: 'clamp(16px, 1vw, 24px)',
    },
    smallboldtext: {
      fontSize: 'clamp(16px, 1vw, 24px)',
      fontWeight: 'bold',
    },
    bigtext: {
      fontSize: '3vw',
      '@media (max-width:600px)': {
        fontSize: '55px',
      },
    },
    tag: {
      fontSize: 'clamp(16px, 1vw, 24px)',
      backgroundColor: '#349e8e',
      color: 'white',
      paddingTop: '5px',
      paddingBottom: '5px',
      paddingLeft: '10px',
      paddingRight: '10px',
      borderRadius: '8px',
    },
    bottomPartContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      gap: '50px',
      flexWrap: 'wrap',

      '@media (max-width:600px)': {
        justifyContent: 'flex-start',
      },
    },
    background_size: {
      background: 'url("/Footer-background-Medium.jpg")',
      backgroundPosition: 'bottom',
      minHeight: '100vh',
      backgroundRepeat: 'no-repeat',
      marginTop: '200px',
      '@media (max-width:800px)': {
        background: 'url("/Footer-background-Mobile.png")',
        backgroundSize: '150%',
        backgroundPosition: '50%',
        minHeight: '83vh',
        marginTop: '100px',
      },
    },
    paddingBottomMobile: {
      paddingBottom: '10vw',
      '@media (max-width:600px)': {
        paddingBottom: '100px',
      },
      '@media (min-width: 1920px) and (max-width: 5000px)': {
        paddingBottom: '230px',
      },
    },
    collapseWidth: {
      width: '125%',
      '@media (max-width:600px)': {
        width: '100%',
      },
    },
    inputfields: {
      '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
      },
    },
    popupMobile: {
      paddingLeft: '100px',
      paddingRight: '100px',
      paddingBottom: '25px',
      '@media (max-width:600px)': {
        paddingLeft: '15px',
        paddingRight: '15px',
        paddingBottom: '15px',
      },
    },
    pharmacistsimageMobile: {
      '@media (max-width:1100px)': {
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      '@media (min-width:1101px)': {
        transform: 'translate(-15%, -5%)',
        alignSelf: 'flex-start',
      },
      '@media (max-width:600px)': {
        paddingTop: '40px',
        width: '110%',
        transform: 'translate(5%)',
      },
    },
    centerdiv: {
      '@media (min-width:601px) and (max-width:1100px)': {
        marginLeft: 'auto',
        marginRight: 'auto',
        transform: 'translate(20%)',
      },
    },
  };
});

const BootstrapDialog = styled(Dialog)(({ theme }) => {
  return {
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
      maxWidth: '500px',
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
    '& .MuiPaper-rounded': {
      borderRadius: '15px',
    },
  };
});

const LandingPagePharmacists = () => {
  const classes = useStyles();
  const [ expanded, setExpanded ] = useState(false);
  const [ open, setOpen ] = React.useState(false);
  const [ firstNameValue, setFirstName ] = useState('');
  const [ lastNameValue, setLastName ] = useState('');
  const [ serialNumberValue, setSerialNumber ] = useState('');
  const [ emailValue, setEmail ] = useState('');

  const { alertError } = useAlerts();

  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  const {
    requiredFieldRule,
    emailRule,
    serialNumberRule,
  } = useFormRules();

  const [
    errorFirstName,
    helperTextFirstName,
    validateFirstName,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorLastName,
    helperTextLastName,
    validateLastName,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorSerialNumber,
    helperTextSerialNumber,
    validateSerialNumber,
  ] = useInputValidation(
    [
      requiredFieldRule,
      serialNumberRule,
    ],
  );

  const [
    errorEmail,
    helperTextEmail,
    validateEmail,
  ] = useInputValidation(
    [
      requiredFieldRule,
      emailRule,
    ],
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const {
      serialNumber,
      firstName,
      lastName,
      email,
    } = Object.fromEntries(formData);

    const formIsValid = ![
      validateSerialNumber(serialNumber),
      validateFirstName(firstName),
      validateLastName(lastName),
      validateEmail(email),
      (() => {
        return true;
      })(),
    ].some((item) => { return !item; });

    if (!formIsValid) {
      return;
    }

    axios.post(`${ process.env.REACT_APP_API_URL }promotion-codes/assign`, {
      serial: serialNumber,
      customer_email: email,
      customer_name: firstName,
      customer_family_name: lastName,
    },
    { headers: {
      'x-api-key': 'pharmacy_page_secret_key_94294ab9-7b64-4029-9762-b62b4416c26b',
    } })
      .then((d) => {
        setOpen(true);
        setFirstName('');
        setLastName('');
        setSerialNumber('');
        setEmail('');
        return d;
      })
      .catch((err) => {
        const code = err.response?.data?.data?.code;
        switch (code) {
          case 'notEligibleForPromotion':
          case 'forbittenThematicPackage':
          case 'notAvailablePromoCodes':
            alertError('Δεν βρέθηκε κουπόνι για αυτόν τον σειριακό αριθμό.');
            break;

          case 'invalidSerialStatus':
            alertError('Ο σειριακός αριθμός έχει ήδη χρησιμοποιηθεί.');
            break;

          case 'serialNotFound':
            alertError('Ο σειριακός αριθμός δεν βρέθηκε.');
            break;

          default:
            alertError(code);
            break;
        }
      });
  };

  return (
    <form
      id='forms'
      noValidate
      onSubmit={ handleSubmit }
    >
      <div className={ classes.container }>
        <div
          className={ classes.mobilescreen }
          style={ { display: 'flex' } }
        >
          <Grid
            item
            container
            xs={ 12 }
            sm={ 10 }
            md={ 8 }
            style={ { marginTop: '8vw',
              position: 'relative' } }
            className={ classes.centerdiv }
          >
            <Grid item container xs={ 12 } sm={ 8 } md={ 8 }>
              <Grid
                item
                className={ classes.tag }
              >
                Για Φαρμακοποιούς
              </Grid>
              <Grid
                item
                className={ classes.bigtext }
              >
                Ενεργοποίησε το promo code σου!
              </Grid>
            </Grid>
            <Grid
              item
              xs={ 12 }
              sm={ 6 }
              md={ 7 }
              className={ classes.smalltext }
              style={ { marginTop: '20px' } }
            >
              Καταχώρησε τα στοιχεία σου και το Serial Number από το kit σου για να λάβεις τον μοναδικό σου εκπτωτικό κωδικό.
            </Grid>
            <Grid
              item
              xs={ 12 }
              sm={ 12 }
              className={ classes.smallboldtext }
              style={ { marginTop: '20px' } }
            >
              Συμπλήρωσε τον Serial Number του kit
            </Grid>
            <Grid
              item
              container
              xs={ 12 }
              sm={ 6 }
              md={ 6 }
              xl={ 5 }
              className={ classes.inputfields }
            >
              <Grid
                item
                xs={ 12 }
                sm={ 12 }
              >
                <TextField
                  id='serialnumber'
                  name='serialNumber'
                  label='Serial Number'
                  variant='outlined'
                  size='small'
                  fullWidth
                  required
                  style={ { marginTop: '20px' } }
                  error={ errorSerialNumber }
                  helperText={ t(helperTextSerialNumber) }
                  validate={ validateSerialNumber }
                  InputLabelProps={ { shrink: '' !== serialNumberValue } }
                  value={ serialNumberValue }
                  onChange={ (e) => { return setSerialNumber(e.target.value.toUpperCase()); } }
                />
              </Grid>
              <Grid
                item
                xs={ 12 }
                sm={ 12 }
              >
                <Button
                  onClick={ () => { return setExpanded(!expanded); } }
                  className={ classes.collapseWidth }
                >
                  <Collapse
                    collapsedSize={ 20 }
                    in={ expanded }
                    timeout={ 1000 }
                    style={ { transform: 'translate(-1%)', textTransform: 'none' } }
                  >
                    <Typography style={ { textAlign: 'left' } }>
                      <div style={ { color: '#6692f2', display: 'flex', flexDirection: 'row', fontSize: 'clamp(5px, 15px, 24px)' } }>
                        Τρόπος εύρεσης του Serial Number
                        <InfoOutlinedIcon style={ { fontSize: 'clamp(5px, 22px, 24px)', paddingLeft: '3px' } } />
                      </div>
                      <div style={ { fontSize: 'clamp(5px, 15px, 24px)', display: 'inline' } }>
                        Το Serial Number θα βρείτε κολλημένο περιμετρικά του κουτιού. Ο κωδικός αυτός έχει την μορφή
                        {' '}
                      </div>
                      <div style={ { fontSize: 'clamp(5px, 15px, 24px)', fontWeight: 'bold', display: 'inline' } }>
                        AB123456
                      </div>
                      <div style={ { fontSize: 'clamp(5px, 15px, 24px)', display: 'inline' } }>
                        , με τα 2 πρώτα ψηφία να αποτελούνται από γράμματα και τα υπόλοιπα έξι από αριθμούς.
                      </div>
                    </Typography>
                  </Collapse>
                </Button>

              </Grid>
              <Grid
                item
                xs={ 12 }
                sm={ 12 }
                className={ classes.smallboldtext }
                style={ { marginTop: '20px' } }
              >
                Προσωπικά Στοιχεία
              </Grid>
              <Grid
                item
                xs={ 12 }
                sm={ 12 }
              >
                <TextField
                  id='firstname'
                  name='firstName'
                  label='Όνομα'
                  variant='outlined'
                  required
                  size='small'
                  fullWidth
                  InputLabelProps={ { shrink: '' !== firstNameValue } }
                  style={ { marginTop: '20px' } }
                  error={ errorFirstName }
                  helperText={ t(helperTextFirstName) }
                  validate={ validateFirstName }
                  value={ firstNameValue }
                  onChange={ (e) => { return setFirstName(e.target.value.toUpperCase()); } }
                />
              </Grid>
              <Grid
                item
                xs={ 12 }
                sm={ 12 }
              >
                <TextField
                  id='lastname'
                  name='lastName'
                  label='Επώνυμο'
                  variant='outlined'
                  required
                  size='small'
                  fullWidth
                  style={ { marginTop: '20px' } }
                  error={ errorLastName }
                  helperText={ t(helperTextLastName) }
                  validate={ validateLastName }
                  InputLabelProps={ { shrink: '' !== lastNameValue } }
                  value={ lastNameValue }
                  onChange={ (e) => { return setLastName(e.target.value.toUpperCase()); } }
                />
              </Grid>
              <Grid
                item
                xs={ 12 }
                sm={ 12 }
              >
                <TextField
                  id='email'
                  name='email'
                  label='Ε-mail'
                  variant='outlined'
                  required
                  size='small'
                  fullWidth
                  style={ { marginTop: '20px' } }
                  requiredField
                  error={ errorEmail }
                  helperText={ t(helperTextEmail) }
                  validate={ validateEmail }
                  InputLabelProps={ { shrink: '' !== emailValue } }
                  value={ emailValue }
                  onChange={ (e) => { return setEmail(e.target.value); } }
                />
              </Grid>
              <Grid
                item
                xs={ 12 }
                style={ { display: 'grid', marginTop: '20px' } }
              >
                <Button
                  type='submit'
                  variant='contained'
                  color='secondary'
                  fullWidth
                  style={ { minHeight: '40px',
                    color: 'white',
                    borderRadius: '8px' } }
                  noValidate
                >
                  <div>
                    Υποβολή
                  </div>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={ classes.pharmacistsimageMobile }>
            <img
              alt='img'
              src='/landingpagepharmacists/img.png'
              height='auto'
              width='100%'
              style={ { objectFit: 'contain' } }
            />
          </Grid>
        </div>

        <>
          <BootstrapDialog
            onClose={ handleClose }
            aria-labelledby='customized-dialog-title'
            open={ open }
            transitionDuration={ 800 }
          >
            <div style={ { display: 'flex', marginLeft: 'auto' } }>
              <IconButton
                aria-label='close'
                onClick={ handleClose }
                sx={ (theme) => {
                  return {
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                  };
                } }
                style={ { borderRadius: '0px' } }
              >
                <Typography style={ { fontSize: '12px' } }>
                  Κλείσιμο
                </Typography>
                <CloseIcon />
              </IconButton>
            </div>
            <div style={ { textAlign: 'center' } } className={ classes.popupMobile }>
              <img
                alt='img'
                src='/myemr-email-check-img.png'
                height='auto'
                width='60%'
                style={ { objectFit: 'contain' } }
              />
              <DialogContent>
                <Typography style={ { fontSize: '20px', fontWeight: 'bold' } }>
                  Ευχαριστούμε!
                </Typography>
                <Typography gutterBottom>
                  <span>
                    Το
                  </span>
                  <span style={ { fontWeight: 'bold' } }>
                    {' '}
                    promo code
                    {' '}
                  </span>
                  <span>
                    σας έχει σταλεί με email.
                  </span>
                </Typography>
              </DialogContent>
            </div>
          </BootstrapDialog>
        </>

        <FifthPage />

      </div>
    </form>
  );
};

export default LandingPagePharmacists;
