import React, { useEffect, useState } from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { IconButton, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useGetCountryFromIP } from 'hooks/util-hooks';
import { FilePDFViewer } from 'components/PDFViewer';
import { corporateTheme } from 'theme/palette';
import SimpleDateField from './atoms/SimpleDateField';
import { useFormRules, useInputValidation } from '../../hooks/form-rules';
import { useBlockingLoader } from '../../hooks/loader-hooks';
import { useAlerts } from '../../hooks/alerts-hooks';
import { useAuth, UserGroups } from '../../AuthCtx';
import { useFormStylesUp } from './form-styles-signup';
import CheckboxDialog from '../app/to-be-refactored/CheckboxDialog';

const genders = [
  { value: 'male', label: 'male' },
  { value: 'female', label: 'female' },
];

export const userGroupToColor = {
  [UserGroups.Patient]: '#94C321',
  [UserGroups.Doctor]: '#526D9C',
  [UserGroups.Corporate]: corporateTheme.primary.main,
};

export const useStyles = makeStyles(() => {
  return {
    doctorColor: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: userGroupToColor[UserGroups.Doctor],
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: userGroupToColor[UserGroups.Doctor],
      },
      '&.checked': {
        color: userGroupToColor[UserGroups.Doctor],
      },
      '& .MuiPickersDay-root': {
        '&.Mui-selected': {
          backgroundColor: userGroupToColor[UserGroups.Doctor],
        },
      },
      borderColor: userGroupToColor[UserGroups.Doctor],
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: userGroupToColor[UserGroups.Doctor],
        },
        '&:hover fieldset': {
          borderColor: userGroupToColor[UserGroups.Doctor],
        },
        '&.Mui-focused fieldset': {
          borderColor: userGroupToColor[UserGroups.Doctor],
        },
      },
    },
    corporateColor: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: userGroupToColor[UserGroups.Corporate],
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: userGroupToColor[UserGroups.Corporate],
      },
      '&.checked': {
        color: userGroupToColor[UserGroups.Corporate],
      },
      '& .MuiPickersDay-root': {
        '&.Mui-selected': {
          backgroundColor: userGroupToColor[UserGroups.Corporate],
        },
      },
      borderColor: userGroupToColor[UserGroups.Corporate],
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: userGroupToColor[UserGroups.Corporate],
        },
        '&:hover fieldset': {
          borderColor: userGroupToColor[UserGroups.Corporate],
        },
        '&.Mui-focused fieldset': {
          borderColor: userGroupToColor[UserGroups.Corporate],
        },
      },
    },
    patientColor: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: userGroupToColor[UserGroups.Patient],
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: userGroupToColor[UserGroups.Patient],
      },
      '&.checked': {
        color: userGroupToColor[UserGroups.Patient],
      },
      '& .MuiPickersDay-root': {
        '&.Mui-selected': {
          backgroundColor: userGroupToColor[UserGroups.Patient],
        },
      },
      borderColor: userGroupToColor[UserGroups.Patient],
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: userGroupToColor[UserGroups.Patient],
        },
        '&:hover fieldset': {
          borderColor: userGroupToColor[UserGroups.Patient],
        },
        '&.Mui-focused fieldset': {
          borderColor: userGroupToColor[UserGroups.Patient],
        },
      },
    },
  };
});

export default function SignUpForm() {
  const formClasses = useFormStylesUp();
  const { t, i18n } = useTranslation();

  const { showBlockingLoader, hideBlockingLoader } = useBlockingLoader('sign-up-form');
  const { alertError } = useAlerts();
  const history = useHistory();

  const { country, loaded: countryLoaded } = useGetCountryFromIP();

  const [ brandName, setBrandName ] = useState('');

  const [ agree, setAgree ] = useState(false);
  const [ doctorAgree, setDoctorAgree ] = useState(false);

  const { requiredFieldRule, emailRule, passwordRules, dateRule, adultRule } = useFormRules();

  const [ firstNameError, firstNameHelperText, validateFirstName ] = useInputValidation(
    [ requiredFieldRule ],
  );

  const [ lastNameError, lastNameHelperText, validateLastName ] = useInputValidation(
    [ requiredFieldRule ],
  );

  const [ dobError, dobHelperText, validateDoB ] = useInputValidation(
    [ dateRule, adultRule ],
  );

  const [ emailError, emailHelperText, validateEmail ] = useInputValidation(
    [ emailRule ],
  );

  const [ passwordError, passwordHelperText, validatePassword ] = useInputValidation(
    passwordRules,
  );

  const [ gender, setGender ] = useState();

  const [ missingGender, setMissingGender ] = useState(false);

  const [ agreeError, agreeHelperText, validateAgree ] = useInputValidation(
    [ (v) => { return !!v || 'You should agree'; } ],
  );

  const [ docAgreeError, docAgreeHelperText, validateDocAgree ] = useInputValidation(
    [ (v) => { return !!v || 'Απαιτείται αποδοχή του Συμφωνητικoύ Επεξεργασίας Προσωπικών Δεδομένων'; } ],
  );

  const classes = useStyles();

  const { signUp, userIsUnconfirmed, userGroup, signIn } = useAuth();

  useEffect(() => {
    if (userIsUnconfirmed) {
      history.push('/account-verification');
    }
  }, [ userIsUnconfirmed ]);

  const validateGender = () => {
    const valid = null != gender;
    if (!valid) {
      setMissingGender(true);
    }
    return valid;
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const {
      firstNameHP,
      lastNameHP,
      birthday,
      email,
      password,
      agreementGDPR,
      docAgreementGDPR,
    } = Object.fromEntries(formData);

    const formIsValid = ![
      (() => {
        if (userGroup === UserGroups.Corporate) {
          return true;
        }
        return validateFirstName(firstNameHP);
      })(),

      (() => {
        if (userGroup === UserGroups.Corporate) {
          return true;
        }
        return validateLastName(lastNameHP);
      })(),

      (() => {
        if (userGroup === UserGroups.Corporate) {
          return true;
        }
        return validateDoB(birthday);
      })(),

      (() => {
        if (userGroup === UserGroups.Corporate) {
          return true;
        }
        return validateGender();
      })(),
      validateEmail(email),
      validatePassword(password),
      validateAgree(agreementGDPR),
      (() => {
        if (userGroup !== UserGroups.Doctor) {
          return true;
        }
        return validateDocAgree(docAgreementGDPR);
      })(),
    ].some((item) => { return !item; });

    if (!formIsValid) {
      return;
    }

    if (userGroup === UserGroups.Corporate) {
      const businessExists = await axios.get(`${ process.env.REACT_APP_API_URL }businesses/findEmail/${ email }`,
        {
          headers: {
            'x-api-key': 'woo_secret_key_8e1fdb02-6173-4062-b3ba-6ba170caac2d',
          },
        })
        .then((s) => { return s.data; })
        .catch((err) => { return err?.response?.data; });

      if ('OK' !== businessExists?.message) {
        if ('businessExists' === businessExists?.data?.code) {
          alertError(t('Ο εταιρικός λογαριασμός υπάρχει ήδη'));
        } else {
          alertError(t('Το email δεν έχει αντιστοιχιστεί με κάποια εταιρία.'));
        }
        return;
      }
    }

    Promise.resolve()
      .then(() => {
        showBlockingLoader();
      })
      .then(() => {
        return signUp({
          username: email,
          password,
          birthdate: birthday,
          firstName: firstNameHP?.toUpperCase(),
          lastName: lastNameHP?.toUpperCase(),
          gender,
          country,
          brandName: brandName?.toUpperCase(),
          emailLanguage: i18n.language,
        });
      })
      .catch((err) => {
        if ('Account already exists' === err.message) {
          signIn({ username: email, password }).catch(() => {
            alertError(err);
          });
        } else {
          alertError(err);
        }
      })
      .finally(() => {
        hideBlockingLoader();
      });
  };

  const [ passwordVisibility, setPasswordVisibility ] = useState(false);
  const togglePasswordVisibility = () => { return setPasswordVisibility((prev) => { return !prev; }); };

  const color = userGroupToColor[userGroup];

  if (!countryLoaded) {
    return null;
  }

  return (
    <Container className={ formClasses.container }>
      <div className={ formClasses.paper }>
        <Typography component='div' variant='h5' style={ { color } }>
          {t('signUp')}
        </Typography>
        <form
          noValidate
          className={ formClasses.form }
          onSubmit={ (e) => {
            return handleSignUp(e);
          } }
        >
          <Grid container spacing={ 2 }>
            {userGroup !== UserGroups.Corporate && (
              <>
                <Grid item xs={ 12 } sm={ 6 }>
                  <TextField
                    InputLabelProps={ { style: { color: firstNameError ? '' : color } } }
                    className={
                      userGroup === UserGroups.Doctor ? classes.doctorColor
                        : userGroup === UserGroups.Corporate ? classes.corporateColor
                          : classes.patientColor
                    }
                    size='small'
                    autoComplete='given-name'
                    name='firstNameHP'
                    variant='outlined'
                    required
                    fullWidth
                    label={ t('firstName') }
                    lettersOnly
                    inputProps={ { style: { textTransform: 'uppercase' } } }
                    error={ firstNameError }
                    helperText={ t(firstNameHelperText) }
                    onChange={ (e) => { return validateFirstName(e.target.value); } }
                    onBlur={ (e) => { return validateFirstName(e.target.value); } }
                  />
                </Grid>
                <Grid item xs={ 12 } sm={ 6 }>
                  <TextField
                    size='small'
                    variant='outlined'
                    lettersOnly
                    InputLabelProps={ { style: { color: lastNameError ? '' : color } } }
                    className={
                      userGroup === UserGroups.Doctor ? classes.doctorColor
                        : userGroup === UserGroups.Corporate ? classes.corporateColor
                          : classes.patientColor
                    }
                    required
                    fullWidth
                    label={ t('lastName') }
                    name='lastNameHP'
                    inputProps={ { style: { textTransform: 'uppercase' } } }
                    autoComplete='family-name'
                    error={ lastNameError }
                    helperText={ t(lastNameHelperText) }
                    onChange={ (e) => { return validateLastName(e.target.value); } }
                    onBlur={ (e) => { return validateLastName(e.target.value); } }
                  />
                </Grid>
              </>
            )}

            {
              userGroup === UserGroups.Doctor && (
                <Grid item xs={ 12 }>
                  <TextField
                    size='small'
                    variant='outlined'
                    InputLabelProps={ { style: { color: lastNameError ? '' : color } } }
                    inputProps={ { style: { textTransform: 'uppercase' } } }
                    className={
                      userGroup === UserGroups.Doctor ? classes.doctorColor
                        : classes.patientColor
                    }
                    value={ brandName }
                    fullWidth
                    label={ t('brandName') }
                    name='brandName'
                    required={ userGroup === UserGroups.Corporate }
                    autoComplete='brand-name'
                    onChange={ (e) => { setBrandName(e.target.value); } }
                    onBlur={ (e) => { setBrandName(e.target.value); } }
                  />
                </Grid>
              )
            }

            {
              userGroup !== UserGroups.Corporate && (
                <>
                  <Grid item xs={ 12 }>
                    <SimpleDateField
                      country={ country }
                      className={ userGroup === UserGroups.Doctor ? classes.doctorColor : classes.patientColor }
                      id='date'
                      label={ t('dateOfBirth') }
                      name='birthday'
                      required
                      validate={ validateDoB }
                      error={ dobError }
                      helperText={ t(dobHelperText) }
                    />
                  </Grid>

                  <Grid item xs={ 12 }>
                    <FormControl component='fieldset'>
                      <FormLabel
                        style={ { color: missingGender ? '#D04747' : '#5f5f5f' } }
                        component='legend'
                      >
                        {`${ t('gender') } *`}
                        <div>
                          (
                          <i>
                            {t('biological gender is defined as the gender you were born with')}
                          </i>
                          )
                        </div>
                      </FormLabel>
                      <RadioGroup
                        row
                        value={ gender }
                        onChange={ (e) => {
                          setMissingGender(false);
                          setGender(e.target.value);
                        } }
                      >
                        {
                          genders.map((option) => {
                            return (
                              <FormControlLabel
                                className={ userGroup === UserGroups.Doctor ? classes.doctorColor : classes.patientColor }
                                key={ option.value }
                                value={ option.value }
                                style={ { color: missingGender ? '#D04747' : '' } }
                                control={ (
                                  <Radio
                                    style={ { color: missingGender ? '#D04747' : color } }
                                  />
                                ) }
                                label={ t(option.label) }
                              />
                            );
                          })
                        }
                      </RadioGroup>
                      { missingGender && (
                        <FormHelperText margin='dense' error style={ { marginTop: '-8px' } }>
                          { t('validation.requiredField') }
                        </FormHelperText>
                      ) }
                    </FormControl>
                  </Grid>
                </>
              )
            }

            <Grid item xs={ 12 }>
              <TextField
                size='small'
                variant='outlined'
                required
                fullWidth
                className={
                  userGroup === UserGroups.Doctor ? classes.doctorColor
                    : userGroup === UserGroups.Corporate ? classes.corporateColor
                      : classes.patientColor
                }
                InputLabelProps={ { style: { color: emailError ? '' : color } } }
                label='Email'
                name='email'
                type='email'
                error={ emailError }
                helperText={ t(emailHelperText) }
                onChange={ (e) => { return validateEmail(e.target.value); } }
                onBlur={ (e) => { return validateEmail(e.target.value); } }
              />
            </Grid>
            <Grid item xs={ 12 }>
              <TextField
                size='small'
                error={ passwordError }
                helperText={ t(passwordHelperText) }
                className={
                  userGroup === UserGroups.Doctor ? classes.doctorColor
                    : userGroup === UserGroups.Corporate ? classes.corporateColor
                      : classes.patientColor
                }
                InputLabelProps={ { style: { color: passwordError ? '' : color } } }
                onChange={ (e) => { return validatePassword(e.target.value); } }
                onBlur={ (e) => { return validatePassword(e.target.value); } }
                variant='outlined'
                required
                fullWidth
                name='password'
                label={ t('password') }
                type={ passwordVisibility ? 'text' : 'password' }
                InputProps={ {
                  endAdornment: (
                    <InputAdornment position='start'>
                      <IconButton onClick={ togglePasswordVisibility }>
                        {!passwordVisibility ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                } }
              />
            </Grid>
          </Grid>

          <Grid container style={ { marginTop: '24px', marginBottom: '8px' } }>
            <Grid item xs={ 12 }>
              <Typography variant='body1' color='textSecondary'>
                {t('I accept the terms and conditions')}
                {' '}
                *
              </Typography>
              <CheckboxDialog
                fullWidth
                maxWidth='lg'
                color={ color }
                className={ userGroup === UserGroups.Doctor ? classes.doctorColor : classes.patientColor }
                label={ t('Read the terms and conditions') }
                name='agreementGDPR'
                dialogProps={ {
                  content: <div><FilePDFViewer path={ 'en-US' === i18n.language ? '/assets/pdfs/Terms of Use & Privacy Policy ENG 13 12 2024.pdf' : '/assets/pdfs/Όροι Χρήσης 27 01 2025.pdf' } /></div>,
                } }
                value={ agree }
                onChange={ (v) => {
                  validateAgree(v);
                  setAgree(v);
                } }
                error={ agreeError }
                helperText={ t(agreeHelperText) }
              />
            </Grid>
          </Grid>

          {
            UserGroups.Doctor === userGroup && (
              <Grid container style={ { marginTop: '24px', marginBottom: '8px' } }>
                <Grid item xs={ 12 }>
                  <Typography variant='body1' color='textSecondary'>
                    {t('Συμφωνητικό Επεξεργασίας Προσωπικών Δεδομένων')}
                    {' '}
                    *
                  </Typography>
                  <CheckboxDialog
                    fullWidth
                    maxWidth='lg'
                    color={ color }
                    className={ userGroup === UserGroups.Doctor ? classes.doctorColor : classes.patientColor }
                    label={ t('Αποδέχομαι το Συμφωνητικό Επεξεργασίας Προσωπικών Δεδομένων') }
                    name='docAgreementGDPR'
                    dialogProps={ {
                      // title: t('Όροι Χρήσης & Πολιτική Ιδιωτικότητας '),
                      content: <div><FilePDFViewer path={ 'en-US' === i18n.language ? '/assets/pdfs/Agreement with Health Professionals ENG 13 12 2024.pdf' : '/assets/pdfs/Συμφωνητικό Επιστημ Υγείας 13 12 2024.pdf' } /></div>,
                    } }
                    value={ doctorAgree }
                    onChange={ (v) => {
                      validateDocAgree(v);
                      setDoctorAgree(v);
                    } }
                    error={ docAgreeError }
                    helperText={ t(docAgreeHelperText) }
                  />
                </Grid>
              </Grid>
            )
          }

          <Grid container justifyContent='space-between'>
            <Grid item />
            <Grid item xs={ 6 }>
              <Button
                fullWidth
                type='submit'
                variant='outlined'
                color='secondary'
                className={ formClasses.submit }
              >
                <Typography
                  variant='body2'
                  color='textSecondary'
                >
                  {t('signUp')}
                </Typography>
              </Button>
            </Grid>
            <Grid item />
          </Grid>
        </form>
      </div>
      <Grid
        className={ formClasses.links }
        container
        direction='column'
        item
        xs={ 12 }
        alignItems='flex-end'
        spacing={ 1 }
      >
        <Grid item>
          <Typography component='div' variant='body2'>
            {`${ t('alreadyHaveAccount') } `}
            <a
              href='/'
              onClick={ (ev) => {
                ev.preventDefault();
                history.push('/');
              } }
            >
              {t('signIn')}
            </a>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
