import React from 'react';
import { useOptionSerial } from 'NavigationProvider';
import ExplanationOfResults from 'pages/ExplanationOfResults';

const ExplanationOfResultsWrapper = () => {
  const { serial } = useOptionSerial('3-in-1');

  return (
    <ExplanationOfResults serial={ serial } />
  );
};

export default ExplanationOfResultsWrapper;
