import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';
import {
  Grid,
  Typography,
  Divider,
} from '@material-ui/core';
import AppTable from 'components/app/AppTable';
import { useTranslation } from 'react-i18next';
import { useItems } from 'models/atoms/items-hook';
import moment from 'moment';
import CollectionKeys from 'models/atoms/collection-keys';
import { LogoImageAndRepeat } from 'pages/DoctorResultsPage';
import { useDoctorIsAbleToGiveConsent } from 'components/layout/doctor-dashboard/navigationPages';
import { results } from 'pages/Results';
import useMediaQueries from 'hooks/media-query-hooks';

const useStyles = makeStyles((_theme) => {
  return {
    filterButton: {
      borderRadius: '100px',
      color: '#3B3B3B',
      fontWeight: 'normal',
      background: '#E5E5E5',
      position: 'relative',
      paddingLeft: '33px',
      textTransform: 'none',
    },
    checkedFilterButton: {
      borderRadius: '100px',
      color: '#3B3B3B',
      fontWeight: 'normal',
      background: '#D0D0D0',
      position: 'relative',
      '&:hover': {
        background: '#D0D0D0',
      },
      paddingLeft: '33px',
      textTransform: 'none',
    },
    iconOnLeft: {
      left: '13px',
      position: 'absolute',
      top: 0,
      bottom: 0,

      height: '20px',
      width: '20px',

      marginTop: 'auto',
      marginBottom: 'auto',
    },
    titleCta: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  };
});

const InvitationsTable = ({ invitations, isLoading, pagination, refetch, thematicPackages }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const isPgxDoctor = useDoctorIsAbleToGiveConsent();

  const allInvitations = useMemo(() => {
    if (!thematicPackages || !invitations) { return []; }

    return invitations
      .map((inv, id) => {
        const kit = inv?.registration_kit;
        const thematicPackage = thematicPackages.find((s) => { return s.id === kit?.thematic_package; });

        const foundKit = results.find((result) => {
          return result.type === thematicPackage.thematic_category_id.name
      || result.alt_type === thematicPackage.thematic_category_id.name || result.other_alt_type === thematicPackage.thematic_category_id.name;
        });

        let type = thematicPackage?.name;

        if ('Cardio Health' === type || 'iDNA Cardio Health' === type || 'iDNA Cardio Health kit' === type) {
          type = 'IDNA Cardio Health';
        }

        let { registrationStatus } = kit;

        if (true === kit.serial_number_id.sample_available && 'registration_done' === kit.registrationStatus) {
          registrationStatus = 'sample_received';
        }

        return {
          id,
          kitId: kit.id,
          status: inv.status,
          created_at: inv.created_at,
          product: type,
          serial: kit.serial_number_id.serial,
          serial_sample_available: kit.serial_number_id.sample_available,
          sendAnalysisReportStatus: kit.sendAnalysisReportStatus,
          reason: inv.reason,
          doctor: `${ inv.metadata?.lastName } ${ inv.metadata?.firstName }`,
          navTitle: foundKit?.navTitle,
          icon: foundKit?.icon,
          title: foundKit?.title,
          style: foundKit?.style,
          pdfAvailable: kit.pdf_available,
          registrationStatus,
          familyName: inv.source?.family_name ?? '',
          firstName: inv.source?.name ?? '',
          email: kit.source?.email ?? '',
        };
      });
  }, [ invitations, isLoading, thematicPackages ]);

  const tableColumns = [
    { id: 1,
      label: t('product'),
      sortable: false,
      field: 'product',
      customRenderer({ row }) {
        return (
          <LogoImageAndRepeat row={ row } />
        );
      } },
    { id: 2, label: 'Serial Number', field: 'serial', sortable: false },
    { id: 3,
      label: isPgxDoctor ? t('patFirstLastName') : t('patCustFirstLastName'),
      field: 'firstName',
      sortable: false,
      customRenderer({ row }) {
        return (
          <div style={ { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' } }>
            <span style={ { paddingLeft: '10px' } }>
              { row.firstName }
              {' '}
              { row.familyName }
            </span>
          </div>
        );
      } },
    { id: 4,
      label: t('Αίτημα'),
      field: '',
      sortable: false,
      customRenderer({ row }) {
        const reason = 'healthcare-professional-share-results' === row.reason
          ? t('Παρακολούθηση') : t('Συγκατάθεση για φαρμακογενετική ανάλυση');

        return (
          <>
            { reason }
          </>
        );
      } },
    { id: 4,
      label: t('Ημερομηνία αιτήματος'),
      field: '',
      sortable: false,
      customRenderer({ row }) {
        return (
          <>
            { moment(row.created_at).format('DD/MM/YYYY') }
          </>
        );
      } },
    { id: 5, label: t('Ιατρός'), field: 'doctor', sortable: false },
    { id: 6,
      label: t('Status αιτήματος'),
      field: '',
      sortable: false,
      customRenderer({ row }) {
        let status = '';
        if ('reject' === row.status) {
          status = t('Απόρριψη');
        } else if ('accept' === row.status) {
          status = t('Αποδοχή');
        } else {
          status = t('Σε αναμονή');
        }

        return (
          <>
            { status }
          </>
        );
      } },
  ];

  return (
    <AppTable
      refetch={ refetch }
      columns={ tableColumns }
      data={ allInvitations }
      loading={ isLoading }
      paginationProps={ pagination }
    />
  );
};

const DoctorResultsPageDesktop = () => {
  const classes = useStyles();

  const { isMd } = useMediaQueries();
  const { t } = useTranslation();

  const {
    items: invitations,
    itemsLoading: isLoading,
    pagination,
    refetch,
  } = useItems(CollectionKeys.Invitations, { defaultPagination: { limit: 5 }, filterQuery: { } });

  const {
    items: thematicPackages,
    itemsLoading: thematicPackagesLoading,
  } = useItems(CollectionKeys.ThematicPackages, { filterQuery: { _limit: -1 } });

  return (
    <Grid container spacing={ isMd ? 4 : 2 }>
      <Grid item xs={ 12 }>
        <div className={ classes.titleCta }>
          <Typography variant='h6' color='textPrimary'>
            {t('invitations')}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={ 12 }>
        <Divider />
      </Grid>
      <Grid container item xs={ 12 }>
        <Grid item xs={ 12 }>
          <InvitationsTable
            thematicPackages={ thematicPackages }
            invitations={ invitations }
            pagination={ pagination }
            refetch={ refetch }
            isLoading={ isLoading || thematicPackagesLoading }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const DoctorResultsPage = () => {
  return (
    <div style={ { width: '100%' } }>
      <DoctorResultsPageDesktop />
    </div>
  );
};

export default DoctorResultsPage;
