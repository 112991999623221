import React, { useContext } from 'react';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { AppCtx } from '../AppCtx';
import { useAuth, UserGroups } from '../AuthCtx';
import { createAppTheme } from './index';

export const ThemeOptionsCtx = React.createContext({});

const AppThemeProvider = (props) => {
  const { themeMode } = useContext(AppCtx);
  const { userGroup } = useAuth();

  return (
    <ThemeProvider theme={ createAppTheme({ themeMode,
      switchPrimarySecondary: userGroup === UserGroups.Doctor,
      isCorporate: userGroup === UserGroups.Corporate }) }
    >
      {props.children}
    </ThemeProvider>
  );
};

export default AppThemeProvider;
