import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import logger from '../logger';

class ErrorBoundary extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(_) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logger.error(error, errorInfo);
    // logger.error(errorInfo);
    console.error('Uncaught error:', error, errorInfo);
  }

  // Find a way to add translation on the message

  render() {
    const { t, isAdmin } = this.props;
    if (this.state.hasError) {
      if (isAdmin) {
        return <h2>{t('There was an error')}</h2>;
      }
      return null;
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
