import React, { useMemo } from 'react';
import { LoadingButtonNoGrid } from 'components/forms/pgx-forms/EntitlePersonalResultsForm';
import { useItems } from 'models/atoms/items-hook';
import CollectionKeys from 'models/atoms/collection-keys';
import * as XLSX from 'xlsx-js-style';
import { useTranslation } from 'react-i18next';

const downloadData = async (cardioKits, pgxKits, nutrigenetixKits, groupedKits) => {
  const data = [
    { A: '', B: 'All Lifechecks' },
    { A: 'NutriGenetix', B: nutrigenetixKits?.length ?? '' },
    { A: 'Cardio', B: cardioKits?.length ?? '' },
    { A: 'PGx', B: pgxKits?.length ?? '' },
    {},
  ];

  const brandRow = { A: '' };
  groupedKits.forEach((kit, index) => {
    brandRow[String.fromCharCode(66 + index)] = kit.brand_name;
  });

  data.push(brandRow);

  const nutrigenetixRow = { A: 'NutriGenetix' };
  const cardioRow = { A: 'Cardio' };
  const pgxRow = { A: 'PGx' };
  const totalKitsRow = { A: '' };

  groupedKits.forEach((kit, index) => {
    const col = String.fromCharCode(66 + index);
    nutrigenetixRow[col] = kit.nutrigenetix;
    cardioRow[col] = kit.cardio;
    pgxRow[col] = kit.pgx;
    totalKitsRow[col] = kit.num_of_kits;
  });

  data.push(nutrigenetixRow);
  data.push(cardioRow);
  data.push(pgxRow);
  data.push(totalKitsRow);

  const worksheet = XLSX.utils.json_to_sheet(data, { skipHeader: true });

  const columnWidths = [{ wch: 20 }];

  groupedKits.forEach((kit) => {
    columnWidths.push({ wch: Math.max(kit.brand_name.length + 5, 15) });
  });

  worksheet['!cols'] = columnWidths;

  worksheet.A1.s = { fill: { fgColor: { rgb: 'F1A983' } } };
  worksheet.B1.s = { fill: { fgColor: { rgb: 'F1A983' } } };
  worksheet.A2.s = { fill: { fgColor: { rgb: 'DAE9F8' } } };
  worksheet.A3.s = { fill: { fgColor: { rgb: 'DAE9F8' } } };
  worksheet.A4.s = { fill: { fgColor: { rgb: 'DAE9F8' } } };
  worksheet.A6.s = { fill: { fgColor: { rgb: 'B5E6A2' } } };
  worksheet.A7.s = { fill: { fgColor: { rgb: 'DAE9F8' } } };
  worksheet.A8.s = { fill: { fgColor: { rgb: 'DAE9F8' } } };
  worksheet.A9.s = { fill: { fgColor: { rgb: 'DAE9F8' } } };

  const brandRowIndex = data.length - groupedKits.length;
  groupedKits.forEach((kit, index) => {
    const col = String.fromCharCode(66 + index);
    const cellRef = `${ col }${ brandRowIndex }`;

    if (!worksheet[cellRef]) {
      worksheet[cellRef] = { v: kit.brand_name };
    }

    worksheet[cellRef].s = {
      fill: { fgColor: { rgb: 'B5E6A2' } },
    };
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  XLSX.writeFile(workbook, 'All Lifechecks.xlsx');
};

export const DownloadLifechecks = (props) => {
  const { t } = useTranslation();

  const {
    items: kits,
    itemsLoading,
  } = useItems(CollectionKeys.DoctorSortedKits,
    { filterQuery: {
      _limit: -1,
      created_at_gte: new Date(props.startDate).toISOString(),
      created_at_lte: new Date(props.endDate).toISOString(),
    } });

  const filteredKits = useMemo(() => {
    return kits?.filter((kit) => {
      return kit.business?.brand_name;
    });
  }, [ kits, props.startDate, props.endDate ]);

  const cardioKits = useMemo(() => {
    return filteredKits?.filter((kit) => {
      return ('IDNA Cardio Health' === kit.thematic_package.thematic_category_id.name
              || 'Cardio Health' === kit.thematic_package.thematic_category_id.name);
    }).map((kit) => {
      return kit.serial_number_id.serial;
    });
  }, [ filteredKits ]);

  const pgxKits = useMemo(() => {
    return filteredKits?.filter((kit) => {
      return 'PGx' === kit.thematic_package.thematic_category_id.name;
    }).map((kit) => {
      return kit.serial_number_id.serial;
    });
  }, [ filteredKits ]);

  const nutrigenetixKits = useMemo(() => {
    return filteredKits?.filter((kit) => {
      return ('NutriGenetix' === kit.thematic_package.thematic_category_id.name)
           || ('NutriGenetix-Laboratories' === kit.thematic_package.thematic_category_id.name);
    }).map((kit) => {
      return kit.serial_number_id.serial;
    });
  }, [ filteredKits ]);

  const groupedKits = [];

  filteredKits?.forEach((kit) => {
    if (!kit.business?.brand_name) { return; }

    const categoryName = kit.thematic_package?.thematic_category_id?.name;
    const isCardio = 'IDNA Cardio Health' === categoryName || 'Cardio Health' === categoryName;
    const isPgx = 'PGx' === categoryName;
    const isNutriGenetix = 'NutriGenetix' === categoryName || 'NutriGenetix-Laboratories' === categoryName;

    const existingEntry = groupedKits.find((entry) => { return entry?.brand_name === kit.business?.brand_name; });

    if (existingEntry) {
      if (isCardio || isPgx || isNutriGenetix) { existingEntry.num_of_kits += 1; }
      if (isCardio) { existingEntry.cardio += 1; }
      if (isPgx) { existingEntry.pgx += 1; }
      if (isNutriGenetix) { existingEntry.nutrigenetix += 1; }
    } else {
      groupedKits.push({
        brand_name: kit.business?.brand_name,
        num_of_kits: 1,
        cardio: isCardio ? 1 : 0,
        pgx: isPgx ? 1 : 0,
        nutrigenetix: isNutriGenetix ? 1 : 0,
      });
    }
  });

  return (
    <LoadingButtonNoGrid
      label={ t('download') }
      onClick={ () => { downloadData(cardioKits, pgxKits, nutrigenetixKits, groupedKits); } }
      variant='contained'
      loading={ itemsLoading }
      disabled={ itemsLoading }
      fullWidth={ false }
      color='secondary'
      style={ { color: 'white', textTransform: 'none' } }
    />
  );
};
