import { Grid, Typography, Avatar, Icon } from '@material-ui/core';
import { useKits } from 'models/kits';
import { useHistory } from 'react-router';
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQueries from 'hooks/media-query-hooks';
import { useTranslation } from 'react-i18next';
import { corporateTheme } from 'theme/palette';
import CardBase from '../app/CardBase';
import SectionAlternate from '../app/SectionAlternate';
import { NavigationProvider, useFetching } from '../../NavigationProvider';
import Menu from './atoms/Menu';
import Pages from './atoms/Pages';
import usePatientNavigationPages from './dashboard/navigationPages';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: '100%',
      width: '100%',
    },
    section: {
      background: '#F1F3F3',
      '& .section-alternate__content': {
        paddingTop: 0,
        marginTop: theme.spacing(-13),
        position: 'relative',
        zIndex: 1,
        marginLeft: '0%',
      },
      '& .card-base__content': {
        padding: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
          padding: theme.spacing(3),
        },
      },
    },
    menu: {
      minHeight: '74vh',
      boxShadow: '0px 24px 80px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '16px',
      background: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'space-between',
      justifyContent: 'space-between',
      height: '100%',
    },
    pagesContainer: {
      padding: '16px',
      overflow: 'hidden',
      [theme.breakpoints.down('xs')]: {
        padding: '16px 5px 5px 5px !important',
      },
    },
    helpContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      border: '2px solid #93C221',
      borderRadius: '15px',
      padding: '10px 0',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    corporateHelpContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      border: `2px solid ${ corporateTheme.primary.main }`,
      borderRadius: '15px',
      padding: '10px 0',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
  };
});

export const Help = ({ style = {}, isCorporate = false }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div
      className={ isCorporate ? classes.corporateHelpContainer : classes.helpContainer }
      style={ { ...style } }
    >
      <Avatar style={ { background: '#F4F4F4' } }>
        <Icon style={ { height: '100%', width: '100%' } }>
          <img
            src='telemarketer-support-svgrepo-com.svg'
            alt=''
            style={ { height: '45px', width: '45px', color: '#666666' } }
          />
        </Icon>
      </Avatar>

      <Typography variant='h6' style={ { marginLeft: '24px' } }>
        {t('needHelp')}
        <br />
        {t('click')}
        {' '}
        <a
          href='https://idnagenomics.com/contact-page/'
          style={ { color: 'inherit', fontWeight: 'bold' } }
          target='_blank'
          rel='noreferrer'
        >
          {t('here')}
        </a>
      </Typography>

    </div>
  );
};

const PatientDashboardInner = () => {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();

  const navigationPages = usePatientNavigationPages();

  const { isSm } = useMediaQueries();

  const { fetchKits } = useFetching();
  const { data: kits } = useKits(fetchKits);
  if (null == kits) {
    return <div />;
  }

  const cardioKits = useMemo(() => {
    return kits?.filter((kit) => {
      return ('IDNA Cardio Health' === kit.thematic_package.thematic_category_id.name
      || 'Cardio Health' === kit.thematic_package.thematic_category_id.name) && 'done' === kit.registrationStatus;
    }).map((kit) => {
      return kit.serial_number_id.serial;
    });
  }, [ kits ]);

  const threeToOneKits = useMemo(() => {
    return kits?.filter((kit) => {
      return '3-in-1' === kit.thematic_package.thematic_category_id.name && 'done' === kit.registrationStatus;
    }).map((kit) => {
      return kit.serial_number_id.serial;
    });
  }, [ kits ]);

  const nutritionKits = useMemo(() => {
    return kits?.filter((kit) => {
      return 'Nutrition' === kit.thematic_package.thematic_category_id.name && 'done' === kit.registrationStatus;
    }).map((kit) => {
      return kit.serial_number_id.serial;
    });
  }, [ kits ]);

  const vitaminsKits = useMemo(() => {
    return kits?.filter((kit) => {
      return 'Vitamins' === kit.thematic_package.thematic_category_id.name && 'done' === kit.registrationStatus;
    }).map((kit) => {
      return kit.serial_number_id.serial;
    });
  }, [ kits ]);

  const sportsKits = useMemo(() => {
    return kits?.filter((kit) => {
      return 'Sports' === kit.thematic_package.thematic_category_id.name && 'done' === kit.registrationStatus;
    }).map((kit) => {
      return kit.serial_number_id.serial;
    });
  }, [ kits ]);

  const bioAgeKits = useMemo(() => {
    return kits?.filter((kit) => {
      return 'Biological Age' === kit.thematic_package.thematic_category_id.name && 'done' === kit.registrationStatus;
    }).map((kit) => {
      return kit.serial_number_id.serial;
    });
  }, [ kits ]);

  const pgxKits = useMemo(() => {
    return kits?.filter((kit) => {
      return 'PGx' === kit.thematic_package.thematic_category_id.name && 'done' === kit.registrationStatus;
    }).map((kit) => {
      return kit.serial_number_id.serial;
    });
  }, [ kits ]);

  const nutrigenetixKits = useMemo(() => {
    return kits?.filter((kit) => {
      return ('NutriGenetix' === kit.thematic_package.thematic_category_id.name && 'done' === kit.registrationStatus)
       || ('NutriGenetix-Laboratories' === kit.thematic_package.thematic_category_id.name && 'done' === kit.registrationStatus);
    }).map((kit) => {
      return kit.serial_number_id.serial;
    });
  }, [ kits ]);

  return (
    <>
      <div className={ classes.root }>
        <div style={
          {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0.3,
          }
        }
        />
        <SectionAlternate className={ classes.section }>
          <Grid container spacing={ 4 } alignItems='flex-start'>
            <Grid
              item
              xs={ 12 }
              md={ 4 }
              lg={ 3 }
              style={ { display: isSm ? 'none' : '' } }
            >
              <CardBase
                withShadow
                align='left'
                className={ classes.menu }
                cardContentProps={ { style: { minHeight: '74vh',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between' } } }
              >
                <Menu
                  pages={
                    navigationPages.map((page) => {
                      const finalPage = { ...page };

                      if ('results' === page.id) {
                        finalPage.items = page.items.filter((item) => {
                          return !Object.prototype.hasOwnProperty.call(item, 'enabled') || item.enabled;
                        }).map((item) => {
                          const newItem = {
                            ...item,
                            items: item.items?.filter((inItem) => {
                              return !Object.prototype.hasOwnProperty.call(inItem, 'enabled') || inItem.enabled;
                            }),
                          };

                          return newItem;
                        });
                      }

                      return finalPage;
                    })
                  }
                  cardioKits={ cardioKits }
                  threeToOneKits={ threeToOneKits }
                  nutritionKits={ nutritionKits }
                  vitaminsKits={ vitaminsKits }
                  sportsKits={ sportsKits }
                  bioAgeKits={ bioAgeKits }
                  pgxKits={ pgxKits }
                  nutrigenetixKits={ nutrigenetixKits }
                />

                <Help />
              </CardBase>

            </Grid>
            <Grid item xs={ 12 } md={ 8 } lg={ 9 } className={ classes.pagesContainer }>
              <Pages pages={ navigationPages } />
            </Grid>
          </Grid>
        </SectionAlternate>
      </div>
    </>
  );
};

const PatientDashboard = () => {
  const { fetchKits } = useFetching();
  const { data: kits } = useKits(fetchKits);
  if (null == kits) {
    return <div />;
  }

  let fallbackPageId = 'general';

  if (1 === kits.length && ('sample_pending' === kits[0].registrationStatus)) {
    fallbackPageId = 'newKitRegister';
  }

  const cardioKits = useMemo(() => {
    return kits?.filter((kit) => {
      return ('IDNA Cardio Health' === kit.thematic_package.thematic_category_id.name
      || 'Cardio Health' === kit.thematic_package.thematic_category_id.name) && 'done' === kit.registrationStatus;
    }).map((kit) => {
      return kit.serial_number_id.serial;
    });
  }, [ kits ]);

  const threeToOneKits = useMemo(() => {
    return kits?.filter((kit) => {
      return '3-in-1' === kit.thematic_package.thematic_category_id.name && 'done' === kit.registrationStatus;
    }).map((kit) => {
      return kit.serial_number_id.serial;
    });
  }, [ kits ]);

  const nutritionKits = useMemo(() => {
    return kits?.filter((kit) => {
      return 'Nutrition' === kit.thematic_package.thematic_category_id.name && 'done' === kit.registrationStatus;
    }).map((kit) => {
      return kit.serial_number_id.serial;
    });
  }, [ kits ]);

  const vitaminsKits = useMemo(() => {
    return kits?.filter((kit) => {
      return 'Vitamins' === kit.thematic_package.thematic_category_id.name && 'done' === kit.registrationStatus;
    }).map((kit) => {
      return kit.serial_number_id.serial;
    });
  }, [ kits ]);

  const sportsKits = useMemo(() => {
    return kits?.filter((kit) => {
      return 'Sports' === kit.thematic_package.thematic_category_id.name && 'done' === kit.registrationStatus;
    }).map((kit) => {
      return kit.serial_number_id.serial;
    });
  }, [ kits ]);

  const bioAgeKits = useMemo(() => {
    return kits?.filter((kit) => {
      return 'Biological Age' === kit.thematic_package.thematic_category_id.name && 'done' === kit.registrationStatus;
    }).map((kit) => {
      return kit.serial_number_id.serial;
    });
  }, [ kits ]);

  const pgxKits = useMemo(() => {
    return kits?.filter((kit) => {
      return 'PGx' === kit.thematic_package.thematic_category_id.name && 'done' === kit.registrationStatus;
    }).map((kit) => {
      return kit.serial_number_id.serial;
    });
  }, [ kits ]);

  const nutrigenetixKits = useMemo(() => {
    return kits?.filter((kit) => {
      return ('NutriGenetix' === kit.thematic_package.thematic_category_id.name && 'done' === kit.registrationStatus)
       || ('NutriGenetix-Laboratories' === kit.thematic_package.thematic_category_id.name && 'done' === kit.registrationStatus);
    }).map((kit) => {
      return kit.serial_number_id.serial;
    });
  }, [ kits ]);

  return (
    <NavigationProvider
      pageKey='pid'
      fallbackPageId={ fallbackPageId }
      cardioSerial={ cardioKits[0] }
      _threeToOneSerial={ threeToOneKits[0] }
      _nutritionSerial={ nutritionKits[0] }
      _vitaminsSerial={ vitaminsKits[0] }
      _sportsSerial={ sportsKits[0] }
      _bioAgeSerial={ bioAgeKits[0] }
      _pgxSerial={ pgxKits[0] }
      _nutrigenetixSerial={ nutrigenetixKits[0] }
    >
      <PatientDashboardInner />
    </NavigationProvider>
  );
};

export default PatientDashboard;
