/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, Route } from 'react-router';
import moment from 'moment';
import axios from 'axios';
import socketIO from 'socket.io-client';
import { AppCtx } from 'AppCtx';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import UnderMaintenancePage from 'pages/UnderMaintenancePage';
import { useQuery } from 'react-query';
import UpdateAvailable from 'components/UpdateAvailable';
import HolidaysNotice from 'components/layout/HolidaysNotice';
import LandingPagePharmacists from 'pages/LandingPagePharmacists';
import LandingPagePharmacistLayout from 'theme/layouts/LandingPagePharmacistLayout';
import CorporateDashboard from 'components/layout/CorporateDashboard';
import CorporateBasicLayout from 'theme/layouts/CorporateBasicLayout';
import BasicLayout from './theme/layouts/BasicLayout';
import DoctorBasicLayout from './theme/layouts/DoctorBasicLayout';
import DeliveryMediumVerificationForm from './components/forms/DeliveryMediumVerificationForm';
import SignUpForm from './components/forms/SignUpForm';
// import SignInForm from './components/forms/SignInForm';
import NotFound from './components/app/NotFound';
import WithLayout from './components/app/WithLayout';
import { AuthRedirect, ProtectedRoute } from './components/routes';
import Logout from './components/app/Logout';
import { SyncStatus, useAuth, UserGroups } from './AuthCtx';
import ForgotPasswordForm from './components/forms/ForgotPasswordForm';
import LandingPage from './pages/LandingPage';
import FormLayout from './theme/layouts/FormLayout';
import KitRegistrationDashboard, { SimpleDashboardWrapper } from './components/layout/KitRegistrationDashboard';
import { DoctorDashboard, PatientDashboard } from './components/layout';
import { useBlockingLoader } from './hooks/loader-hooks';
import { useKits } from './models/kits';
import DoctorDetailsForm from './components/forms/doctor-forms/DoctorDetailsForm';

const PatientRouteAccessResolver = () => {
  const { isLoading: kitsLoading, existsCompleteKitRegistration } = useKits();
  useBlockingLoader('kits-loading', kitsLoading);

  if (kitsLoading) {
    return null;
  }

  if (existsCompleteKitRegistration) {
    return (
      <WithLayout
        component={ PatientDashboard }
        layout={ BasicLayout }
      />
    );
  }

  return <Redirect to='/register-kit' />;
};

const DoctorDetailsPage = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <Alert severity='info'>
          {t('Παρακαλώ εισάγεται τα απαιτούμενα στοιχεία. Τα δεδομένα αυτά θα χρησιμοποιηθούν προκειμένου η iDNA να μπορέσει να έρθει σε επικοινωνία.')}
        </Alert>
      </Container>
      <DoctorDetailsForm { ...props } />
    </>
  );
};

const DoctorRouteAccessResolver = () => {
  const { doctorHasRequiredAttributes } = useAuth();

  if (!doctorHasRequiredAttributes) {
    return (
      <SimpleDashboardWrapper>
        <DoctorDetailsPage />
      </SimpleDashboardWrapper>
    );
  }

  return (
    <WithLayout
      component={ DoctorDashboard }
      layout={ DoctorBasicLayout }
    />
  );
};

const CorporateRouteAccessResolver = () => {
  return (
    <WithLayout
      component={ CorporateDashboard }
      layout={ CorporateBasicLayout }
    />
  );
};

const RouteAccessResolver = () => {
  const { userGroup, userAttributesLoaded } = useAuth();
  if (!userAttributesLoaded) {
    return null;
  }

  switch (userGroup) {
    case UserGroups.Patient:
      return <PatientRouteAccessResolver />;
    case UserGroups.Doctor:
      return <DoctorRouteAccessResolver />;
    case UserGroups.Corporate:
      return <CorporateRouteAccessResolver />;
    default:
      throw new Error('Bad configuration');
  }
};

const KitRegisterRouteResolver = () => {
  const { existsCompleteKitRegistration } = useKits();

  if (existsCompleteKitRegistration) {
    return <Redirect to='/' />;
  }

  return (
    <WithLayout
      component={ KitRegistrationDashboard }
      layout={ BasicLayout }
    />
  );
};

function App() {
  const { userIsAuthenticated, userIsUnconfirmed, syncStatus } = useAuth();
  useBlockingLoader('sync-status', [ SyncStatus.Idle, SyncStatus.Loading ].includes(syncStatus));

  const { showUpdateAvailable, setShowUpdateAvailable } = React.useContext(AppCtx);
  const [ showAppMaintenance, setShowAppMaintenance ] = useState(false);

  const [ alreadySawHolidaysNotice, setAlreadySawHolidaysNotice ] = useState(localStorage.getItem('holidays-notice-emr') || 'false');

  const isHolidayPeriod = useMemo(() => {
    return moment().isBetween('2024-08-02T00:00:00+03:00', '2024-08-18T23:59:59+03:00');
  }, [ ]);

  const closeHolidaysNotice = () => {
    localStorage.setItem('holidays-notice-emr', 'true');
    setAlreadySawHolidaysNotice('true');
  };

  useQuery([ 'app', 'version' ], () => {
    return axios.get(`${ process.env.REACT_APP_API_URL }client-versions?client_name_eq=web_client`,
      {
        headers: {
          'x-api-key': 'mobile_customer_secret_key_8bjg2a91-4519-6924-a4hi-5cc732edg79k',
        },
      }).then((s) => {
      if (s.data?.[0].client_version !== process.env.REACT_APP_VERSION) {
        setShowUpdateAvailable(true);
      }
    });
  },
  {
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60,
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    const socket = socketIO.connect(process.env.REACT_APP_API_URL);
    socket.on('web_client', (dd) => {
      if ('-1' === dd.client_version) {
        setShowAppMaintenance(true);
      } else {
        setShowAppMaintenance(false);
        if (dd.client_version !== process.env.REACT_APP_VERSION) {
          setShowUpdateAvailable(true);
        }
      }
    });

    socket.on('error', () => {
      setTimeout(() => {
        socket.connect();
      }, 5000);
    });

    socket.on('disconnect', () => {
      setTimeout(() => {
        socket.connect();
      }, 5000);
    });
  }, []);

  if ([ SyncStatus.Idle, SyncStatus.Loading ].includes(syncStatus)) {
    return null;
  }

  if (SyncStatus.Error === syncStatus) {
    return <p>Server Error</p>;
  }

  if ('true' === process.env.REACT_APP_MAINTENANCE || showAppMaintenance) {
    return (
      <UnderMaintenancePage />
    );
  }

  return (
    <>
      {
        showUpdateAvailable && (
          <UpdateAvailable />
        )
      }

      {
        'true' !== alreadySawHolidaysNotice && isHolidayPeriod && (
          <HolidaysNotice
            open={ alreadySawHolidaysNotice }
            handleClose={ closeHolidaysNotice }
          />
        )
      }
      <Router>
        <Switch>
          <Route
            path='/my-emr'
            exact
            render={ (props) => {
              return (
                !userIsAuthenticated
                  ? (
                    <WithLayout
                      component={ LandingPage }
                      props={ props }
                      layout={ BasicLayout }
                    />
                  )
                  : <AuthRedirect { ...props } />);
            } }
          />
          <Route
            path='/pharmacy'
            exact
            render={ (props) => {
              return (
                <WithLayout
                  component={ LandingPagePharmacists }
                  props={ props }
                  layout={ LandingPagePharmacistLayout }
                />
              );
            } }
          />
          <ProtectedRoute
            auth={ userIsAuthenticated }
            exact
            path='/'
            redirectTo={ {
              pathname: '/my-emr',
            } }
            component={ RouteAccessResolver }
          />
          <ProtectedRoute
            auth={ userIsAuthenticated }
            exact
            path='/register-kit'
            component={ KitRegisterRouteResolver }
          />
          <ProtectedRoute
            auth={ userIsAuthenticated }
            exact
            path='/logout'
            redirectTo={ { pathname: '/' } }
            component={ Logout }
          />
          <Route
            exact
            path='/login'
            render={ (props) => {
              return !userIsAuthenticated
                ? (
                  <WithLayout
                    component={ LandingPage }
                    props={ props }
                    layout={ BasicLayout }
                  />
                )
                : <AuthRedirect { ...props } />;
            } }
          />
          <Route
            path='/password-recovery'
            exact
            render={ (props) => {
              return !userIsAuthenticated
                ? (
                  <WithLayout
                    component={ ForgotPasswordForm }
                    props={ props }
                    layout={ FormLayout }
                  />
                )
                : <AuthRedirect { ...props } />;
            } }
          />

          <Route
            path='/register'
            exact
            render={ (props) => {
              return !userIsAuthenticated
                ? (
                  <WithLayout
                    component={ SignUpForm }
                    props={ props }
                    layout={ FormLayout }
                  />
                )
                : <AuthRedirect { ...props } />;
            } }
          />
          <Route
            path='/account-verification'
            exact
            render={ (props) => {
              return (userIsUnconfirmed)
                ? (
                  <WithLayout
                    component={ DeliveryMediumVerificationForm }
                    layout={ FormLayout }
                  />
                )
                : <AuthRedirect { ...props } />;
            } }
          />
          <ProtectedRoute
            auth={ userIsAuthenticated }
            path='/*'
            component={ () => {
              return <NotFound />;
            } }
          />
        </Switch>
      </Router>
    </>

  );
}

export default App;
