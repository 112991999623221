import React, { useState, useEffect } from 'react';
import { usePrevious } from 'hooks/util-hooks';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const TheAccordion = (props) => {
  const [ isActive, setIsActive ] = useState(props.expanded);

  const prevActive = usePrevious(props.expanded);

  useEffect(() => {
    if (!prevActive) {
      setIsActive(props.expanded);
    }
  }, [ props.expanded ]);

  const { hideExpandIcons } = props;

  const topDistance = hideExpandIcons || props.isCorporate ? (props.isFirstPage ? '20px' : '19px') : '13px';
  const lastPageTopDistance = hideExpandIcons ? '-0px' : '-6px';

  return (
    <div style={ { position: 'relative' } }>
      { props.isTopLevel && !props.isLastPage && (
        <div style={ { width: '2px',
          height: '100%',
          background: '#F1F3F3',
          position: 'absolute',
          top: '-6px',
          left: '32px' } }
        />
      )}

      { props.isTopLevel && (
        <div style={ {
          position: 'absolute',
          borderLeft: '2px solid #F1F3F3',
          borderBottom: '2px solid #F1F3F3',
          top: props.isLastPage ? lastPageTopDistance : topDistance,
          left: '32px',
          height: props.isLastPage ? '42px' : props.isFirstPage ? '21px' : '21px',
          width: '24px',
          borderBottomLeftRadius: '19px',
        } }
        />
      )}

      <div
        aria-hidden
        onClick={ () => {
          if (true !== hideExpandIcons) {
            setIsActive(!isActive);
          }
        } }
      >
        <div style={ { position: 'relative' } }>
          {props.title}
          {
            isActive ? (
              <ExpandLessIcon
                style={ {
                  cursor: 'pointer',
                  position: 'absolute',
                  display: hideExpandIcons ? 'none' : undefined,
                  right: '10px',
                  top: '33%',
                  color: props.isActive ? 'white' : '#484848',
                } }
              />
            ) : (
              <ExpandMoreIcon
                style={ {
                  position: 'absolute',
                  right: '10px',
                  display: hideExpandIcons ? 'none' : undefined,
                  cursor: 'pointer',
                  top: '33%',
                  color: props.isActive ? 'white' : '#484848',
                } }
              />
            )
          }
        </div>

      </div>
      {isActive && (
        <div className='accordion-content'>
          {/* {
            props.isTopLevel && (
              <FolderLine numOfItems={ props.numOfItems } />
            )
          } */}
          {props.children}
        </div>
      )}
    </div>
  );
};

export default TheAccordion;
