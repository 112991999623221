import React, { useMemo } from 'react';
import { makeStyles, useMediaQuery, Grid } from '@material-ui/core';
import { I18nextProvider, useTranslation } from 'react-i18next';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useOptionSerial, useNavigation } from 'NavigationProvider';
import { useAuth } from 'AuthCtx';
import { Pdf } from 'pages/iDNACardioHealth/iDNACardioHealth';
import i18nEl from 'i18n_el';
import i18nEng from 'i18n_eng';
import { usePgxDataReport } from 'models/kits';

export const blueColor = '#526A9D';

const useStyles = makeStyles(() => {
  return {
    normalText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '161.19%',
      color: '#484848',
    },
    singleMedicineTitle: {
      background: blueColor,
      color: '#F1F3F3',
      borderRadius: '15px 15px 0 0',
      height: '82px',
      paddingBottom: '10px',
      fontWeight: '700',
      // fontSize: '20px',
      fontSize: '1.5em',
      lineHeight: '137.19%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      top: '20px',
      boxShadow: '0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054)',
    },
    singleMedicineItem: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '110.19%',
      background: '#526A9D',
      boxShadow: '0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054)',
      borderRadius: '6px',
      padding: '11px',
      color: 'white',
    },
    cardIcon: {
      height: '71px',
      width: '71px',
      display: 'flex',
      padding: '15px',
      boxShadow: '0px 24px 55px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '10px',
    },
    page: {
      display: 'flex',
      padding: '30px',
      boxShadow: '0px 24px 55px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
      minHeight: '250px',
      borderRadius: '16px',
      flexWrap: 'nowrap',
      gap: '0',
      '@media (max-width:600px)': {
        flexWrap: 'wrap',
        gap: '8px',
      },
    },
    smallText: {
      fontWeight: '400',
      fontSize: '16px',
      fontFamily: 'Roboto',
      lineHeight: '125.19%',
    },
    learnMoreArrow: {
      marginTop: '3px',
      transform: 'rotate(-45deg)',
    },
    cardField: {
      display: 'flex',
      alignItems: 'center',
      gap: '30px',
      marginLeft: '8%',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    topPart: {
      background: 'white',
      backdropFilter: 'blur(40px)',
      boxShadow: '0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054)',
      borderRadius: '15px',
    },
    title: {
      marginTop: '24px',
      fontWeight: '600',
      fontFamily: 'Roboto',
      fontSize: '39px',
      lineHeight: '46px',
      paddingLeft: '47px',
    },
    modalTitle: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '43.4717px',
      lineHeight: '60px',
      color: '#484848',
    },
    modalContainer: {
      borderRadius: '15px',
      padding: '40px 70px',
      position: 'relative',
    },
    subtitle: {
      marginTop: '10px',
      fontWeight: '400',
      fontFamily: 'Roboto',
      fontSize: '12px',
      lineHeight: '125.19%',
      paddingLeft: '47px',
    },
    hello: {
      position: 'relative',
      zIndex: 30,
      fontWeight: '700',
      display: 'flex',
      alignItems: 'center',
      gap: '30px',
      boxShadow: '0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054)',
      borderRadius: '15px',
      fontSize: '28px',
      fontFamily: 'Roboto',
      paddingLeft: '40px',
      lineHeight: '137.19%',
      color: 'white',
      height: '110px',
      paddingBottom: '10px',
      background: blueColor,
    },
    whatYouWillFind: {
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '140%',
      color: '#42557E',
    },
    infoOuter: {
      background: blueColor,
      width: '100%',
      height: '100%',
      padding: '25px',
      borderRadius: '15px',
      position: 'relative',
    },
    infoInner: {
      background: 'white',
      padding: '40px',
      borderRadius: '15px',
    },
    learnMore: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: '-22px',
      zIndex: 3900,
      marginLeft: 'auto',
      borderBottom: `5px solid ${ blueColor }`,
      marginRight: 'auto',
      textAlign: 'center',
      background: 'white',
      width: '70%',
      height: '50px',
      boxShadow: '0px 30.3396px 101.132px rgba(0, 0, 0, 0.05), 0px 6.77675px 22.5892px rgba(0, 0, 0, 0.0298054), 0px 2.01762px 6.72539px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '27px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '@media (max-width:600px)': {
        bottom: '-25px',
      },

      '&:hover': {
        cursor: 'pointer',
      },
    },
    pdf: {
      background: 'white',
      height: '200px',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      padding: '40px',
      borderRadius: '15px',
      justifyContent: 'space-between',
    },
    downloadIcon: {
      width: '100px',
      height: '100px',
      left: '48px',
      top: '44px',
      boxShadow: '0px 24px 55px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '30px',
    },
    hoverPointer: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    categories: {
      borderRadius: '15px',
      padding: '30px',
      background: blueColor,
      display: 'flex',
      position: 'relative',
    },
    category: {
      color: 'white',
    },
    innerCategories: {
      background: 'white',
      width: '100%',
      padding: '70px 100px 70px 100px',
      borderRadius: '15px',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'nowrap',
    },
    innerCategoriesMobile: {
      background: 'white',
      width: '100%',
      display: 'flex',
      gap: '30px',
      flexWrap: 'wrap',
      padding: '5px',
    },
  };
});

const PgxInner = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { serial: newSerial } = useOptionSerial('pgx');

  const { navigateToPage } = useNavigation();

  const isTabletSize = useMediaQuery('(max-width:1385px)');
  const isSmallTabletSize = useMediaQuery('(max-width:1175px)');

  const { userAttributes } = useAuth();

  if (!userAttributes) {
    return null;
  }

  const {
    firstName,
  } = userAttributes;

  return (
    <div style={ { display: 'flex', flexDirection: 'column', gap: '20px' } }>
      <div className={ classes.topPart }>
        <div style={ { display: 'flex', justifyContent: 'space-between', position: 'relative' } }>
          <div style={ { marginTop: '1rem', marginBottom: '24px', position: 'relative', zIndex: 40 } }>
            <div
              className={ classes.title }
            >
              iDNA PGx CNS Report
            </div>
            <div className={ classes.subtitle }>
              Serial Number:
              {' '}
              {newSerial}
            </div>
          </div>
          <img
            src={ isTabletSize ? '/pgx-image-small.png' : '/pgx-image.png' }
            alt=''
            style={ { display: isSmallTabletSize ? 'none' : '', margin: 0, position: 'absolute', bottom: '-40px', right: 0 } }
          />
        </div>
        <div className={ classes.hello }>
          <AccountCircleIcon style={ { transform: 'scale(2.5)' } } />
          <div>
            {
              `${ t('hello') } ${ firstName },`
            }
          </div>
        </div>
        <div
          style={ {
            padding: '30px',
            position: 'relative',
            zIndex: 30,
            background: 'white',
            top: '-20px',
            borderRadius: '15px',
          } }
        >
          <Grid
            container
            spacing={ 2 }
          >
            <Grid item lg={ 6 }>
              <div
                style={ {
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  padding: '30px',
                  background: '#EAEEF6',
                  borderRadius: '15px',
                } }
              >
                <div
                  className={ classes.whatYouWillFind }
                >
                  {t('hereYouWillFind')}
                </div>
              </div>

              <div style={ {
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '161.19%',
                color: '#484848',
                marginTop: '21px',
                marginBottom: '21px',
              } }
              >
                {t('Ο γονιδιακός έλεγχος περιλαμβάνει τη Φαρμακογενετική ανάλυση για 30 φάρμακα.')}
              </div>

              <Grid container spacing={ 2 }>
                <Grid item xs={ 12 } sm={ 6 }>
                  <div className={ classes.singleMedicineItem }>
                    {t('Αντιψυχωσικά Φάρμακα')}
                  </div>
                </Grid>

                <Grid item xs={ 12 } sm={ 6 }>
                  <div className={ classes.singleMedicineItem }>
                    {t('Αντικαταθλιπτικά Φάρμακα')}
                  </div>
                </Grid>

                <Grid item xs={ 12 } sm={ 6 }>
                  <div className={ classes.singleMedicineItem }>
                    {t('Αντιεπιληπτικά Φάρμακα')}
                  </div>
                </Grid>

                <Grid item xs={ 12 } sm={ 6 }>
                  <div className={ classes.singleMedicineItem }>
                    {t('Άλλα φάρμακα ΚΝΣ')}
                  </div>
                </Grid>
              </Grid>

              <div
                style={ {
                  marginTop: '21px',
                  marginBottom: '21px',
                } }
                className={ classes.normalText }
              >
                {t('Με βάση τη Φαρμακογενετική Ανάλυση επιτυγχάνονται:')}
              </div>

              <div style={ { display: 'flex', gap: '10px', marginBottom: '20px' } }>
                <img src='/icon-arrow.svg' alt='/icon-arrow.svg' />
                <div className={ classes.normalText }>{t('Λιγότερες ανεπιθύμητες ενέργειες')}</div>
              </div>

              <div style={ { display: 'flex', gap: '10px', marginBottom: '20px' } }>
                <img src='/icon-arrow.svg' alt='/icon-arrow.svg' />
                <div className={ classes.normalText }>{t('Βέλτιστη επιλογή θεραπείας και µείωση της πιθανότητας αποτυχίας της αγωγής')}</div>
              </div>

              <div style={ { display: 'flex', gap: '10px' } }>
                <img src='/icon-arrow.svg' alt='/icon-arrow.svg' />
                <div className={ classes.normalText }>{t('Καλύτερη ποιότητα ζωής για τον ασθενή')}</div>
              </div>
            </Grid>

            <Grid item lg={ 6 }>
              <img
                src='myEMR-Doctor-Patient-Report-round-829x788px-4K.png'
                alt='myEMR-Doctor-Patient-Report-round-829x788px-4K.png'
                style={ { width: '100%', height: 'auto' } }
              />
            </Grid>

          </Grid>
        </div>
      </div>
      <Pdf navigateToPage={ navigateToPage } url='pgxPdfVersion' />
    </div>
  );
};

const PGx = () => {
  const { serial } = useOptionSerial('pgx');
  const { locale } = usePgxDataReport(serial);

  const i18nProv = useMemo(() => {
    if ('el-GR' === locale) {
      return i18nEl;
    }

    return i18nEng;
  }, [ locale ]);

  return (
    <I18nextProvider i18n={ i18nProv }>
      <PgxInner />
    </I18nextProvider>
  );
};

export default PGx;
