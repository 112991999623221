import { Grid, Divider, makeStyles, Typography } from '@material-ui/core';
import { useItems } from 'models/atoms/items-hook';
import React, { useEffect } from 'react';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useTranslation } from 'react-i18next';
import useMediaQueries from 'hooks/media-query-hooks';
import { useAuth } from 'AuthCtx';

const useStyles = makeStyles(() => {
  return {
    item: {
      backgroundColor: 'white',
      boxShadow: '15px',
      fontFamily: 'Roboto',
      display: 'flex',
      flexDirection: 'column',
      gap: '7px',
      marginBottom: '20px',
      position: 'relative',
      borderRadius: '10px',
      border: '1px solid #d9d7d7',
    },
    container: {
      width: '100%',
      borderRadius: '15px',
    },
    leftColor: {
      width: '9px',
      height: '100%',
      borderTopLeftRadius: '50px',
      borderBottomLeftRadius: '50px',
      position: 'absolute',
      left: '0px',
      top: '0px',
    },
  };
});

const QuestionnaireItem = ({ question, color, questionVariant, questionColor,
  smallGap, customerCountry, isDoctor, isCorporate, isChild }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  if (null == question.answerText || 0 === question.answerText.length) {
    return null;
  }

  return (
    <Grid
      item
      xs={ 12 }
      className={ classes.item }
      style={ {
        flexDirection: smallGap ? 'row' : 'column',
        flexWrap: smallGap ? 'wrap' : 'nowrap',
        marginBottom: smallGap ? '10px' : '20px',
      } }
    >
      <div
        className={ classes.leftColor }
        style={ { background: color } }
      />

      <Typography
        variant={ questionVariant }
        style={ { marginLeft: '8px', color: questionColor } }
        display='inline'
      >
        {'US' === customerCountry
          ? question.questionText?.replace('kg', 'pounds')?.replace('cm', 'feet')
          : question.questionText}

        <>
          {
            question.questionSubText && '' !== question.questionSubText && (
              <Typography
                style={ { marginLeft: '5px',
                  fontWeight: 'bold',
                  color: questionColor } }
                display='inline'
                variant={ questionVariant }
              >

                (
                {question.questionSubText}
                )
              </Typography>
            )
          }
        </>

      </Typography>
      <Typography
        style={ { marginLeft: '8px', color: '#006fc0' } }
        display='inline'
        variant={ questionVariant }
      >

        {
          'date_integer' === question.type ? (
            <div>
              <span style={ { color: questionColor, marginBottom: '5px' } }>
                Μετρήσεις:
              </span>
              {
                question.answerText.reduce((rows, key, index) => {
                  return (0 === index % 2 ? rows.push([ key ])
                    : rows[rows.length - 1].push(key)) && rows;
                }, [])
                  .map((item, i) => {
                    return (
                      <div
                        key={ item }
                        style={ { display: 'flex' } }
                      >
                        <span style={ { marginRight: '10px' } }>
                          { i + 1 }
                          )
                        </span>
                        <div style={ { display: 'flex', gap: '10px' } }>
                          <div>
                            {`${ 'en-US' === i18n.language ? question.description_1_en : question.description_1_el }: ${ item[0] }`}
                            {', '}
                          </div>
                          <div>
                            {`${ 'en-US' === i18n.language ? question.description_2_en : question.description_2_el }: ${ item[1] }`}
                          </div>
                        </div>
                      </div>
                    );
                  })
              }
            </div>
          )
            : (
              <>
                {
                  'three_integers' === question.type ? (
                    <div style={ { display: 'flex', gap: '5px' } }>

                      <div>
                        {
                          `${ t('bioAgeValueLbl') }: ${ question.answerText.split('/')[0] }, `
                        }
                      </div>
                      <div>
                        {
                          `${ t('bioAgeFromLbl') }-${ t('bioAgeToLbl') }: ${ question.answerText.split('/')[1] }-${ question.answerText.split('/')[2] }`
                        }
                      </div>

                    </div>
                  )
                    : 'two_floats' === question.type ? (
                      <div style={ { display: 'flex', gap: '5px' } }>

                        <div>
                          {
                            `${ t('bioAgeValueLbl') }: ${ question.answerText.split('/')[0] }, `
                          }
                        </div>
                        <div>
                          {
                            `< ${ question.answerText.split('/')[1] }`
                          }
                        </div>

                      </div>
                    )
                      : (
                        <>
                          {
                            question.answerText.constructor === Array && (
                              <div>
                                {
                                  smallGap ? (
                                    <>
                                      {question.answerText.map((item, idx) => {
                                        let questionText = '';

                                        if (!('string' === typeof item || item instanceof String)) {
                                          questionText = Object.values(item)?.[0];
                                        } else {
                                          const parsedItem = item;

                                          const optionIdx = question.options.findIndex((s) => { return s === parsedItem; });

                                          if ('en-US' === i18n.language) {
                                            questionText = question.options_en[optionIdx];
                                          } else {
                                            questionText = question.options_el[optionIdx];
                                          }
                                        }

                                        return (
                                          <span key={ questionText }>
                                            { questionText}
                                            { `${ question.answerText.length - 1 === idx ? '' : ', ' }`}
                                            {' '}
                                          </span>
                                        );
                                      }) }
                                    </>
                                  )
                                    : (
                                      <>
                                        {question.answerText.map((item, idx) => {
                                          let questionText = '';

                                          if (!('string' === typeof item || item instanceof String)) {
                                            questionText = Object.values(item)?.[0];
                                          } else {
                                            const parsedItem = item;

                                            const optionIdx = question.options.findIndex((s) => { return s === parsedItem; });

                                            if ('en-US' === i18n.language) {
                                              questionText = question.options_en[optionIdx];
                                            } else {
                                              questionText = question.options_el[optionIdx];
                                            }
                                          }

                                          return (
                                            <div key={ item }>
                                              { questionText}
                                              { `${ question.answerText.length - 1 === idx ? '' : ',' }`}
                                            </div>
                                          );
                                        }) }
                                      </>
                                    )
                                }
                              </div>
                            )
                          }
                          {
                            question.answerText.constructor !== Array && (
                              <>
                                {('one_choice' === question.type)
                                  ? ((isDoctor || isCorporate) ? t(`doc_${ question.answerText }`)
                                    : isChild ? t(`child_${ question.answerText }`)
                                      : t(question.answerText)) : question.answerText }
                              </>
                            )
                          }
                        </>
                      )
                }
              </>
            )
        }

      </Typography>
    </Grid>
  );
};

const QuestionnaireAnswers = ({ serial, color, showTitle = true,
  titleVariant = 'h4', titleColor = '', setIsQuestionnaireLoaded = null,
  questionVariant = 'h5', questionColor = 'black', smallGap = false,
  titleClassName = undefined, isChild }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { isSm } = useMediaQueries();

  const { isDoctor, isCorporate } = useAuth();

  const {
    items: answers,
    itemsLoading: answersIsLoading,
  } = useItems(`customer-questionnaire-answers?serial_number.serial=${ serial }`);

  const customerCountry = answers?.[0]?.customer?.country ?? 'GR';

  useEffect(() => {
    if (null != setIsQuestionnaireLoaded) {
      if (answersIsLoading || null == answers || null == answers[0]?.questionnaire?.questions) {
        setIsQuestionnaireLoaded(false);
      } else {
        setIsQuestionnaireLoaded(true);
      }
    }
  }, [ answersIsLoading, answers ]);

  if (answersIsLoading || null == answers || null == answers[0]?.questionnaire?.questions) {
    return <LoaderSkeleton />;
  }

  let offset = 0;

  const docOrPatQuest = isDoctor || isCorporate
    ? answers[0]?.questionnaire?.questions_doctor
    : isChild ? answers[0]?.questionnaire?.questions_child
      : answers[0]?.questionnaire?.questions;

  const questions = docOrPatQuest?.filter((quest) => {
    const newId = Number(quest.id) + 2;
    return null != answers[0]?.value[newId] && '' !== answers[0]?.value[newId];
  })
    .map((quest) => {
      const newId = Number(quest.id) + offset + 2;

      const isThreeIntegers = 'three_integers' === quest.type || 'two_floats' === quest.type;
      if (isThreeIntegers) {
        offset += 1;
      }

      let answerText = answers[0].value[newId];

      if (isThreeIntegers) {
        // eslint-disable-next-line prefer-template
        answerText += '/' + answers[0].value[newId + 1];
      }

      if ('multiple' === quest.times) {
        const numOfItems = Object.keys(answers[0].value).length;
        answerText = [];

        for (let i = newId; i < numOfItems; i++) {
          if (null != answers[0].value[i] && '' !== answers[0].value[i]) {
            answerText.push(answers[0].value[i]);
          }
        }
      }

      return {
        id: newId,
        questionText: 'en-US' === i18n.language ? quest.description_en : quest.description_el,
        questionSubText: 'en-US' === i18n.language ? quest.sub_description_en : quest.sub_description_el,
        answerText,
        ...quest,
      };
    });

  return (
    <div className={ classes.container }>
      {
        showTitle && (
          <div>
            <Typography
              variant={ titleVariant }
              style={ { marginBottom: '10px', color: titleColor } }
              className={ titleClassName }
            >
              {t('questionnaireAnswers')}
              <Divider style={ { width: '60%', marginTop: '5px' } } />
            </Typography>
          </div>
        )
      }
      <Grid
        container
        spacing={ (isSm || smallGap) ? 2 : 3 }
        style={ { margin: 0 } }
      >
        {
          questions.map((question) => {
            return (
              <QuestionnaireItem
                isDoctor={ isDoctor }
                isCorporate={ isCorporate }
                key={ question.id }
                smallGap={ smallGap }
                questionColor={ questionColor }
                questionVariant={ questionVariant }
                color={ color }
                question={ question }
                customerCountry={ customerCountry }
                isChild={ isChild }
              />
            );
          })
        }
      </Grid>
    </div>
  );
};

export default QuestionnaireAnswers;
