import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Divider } from '@material-ui/core';
import CorporateDetailsForm from 'components/forms/corporate-forms/CorporateDetailsForm';
import useMediaQueries from '../hooks/media-query-hooks';

const useStyles = makeStyles((_theme) => {
  return {
    titleCta: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    paper: { minWidth: '770px' },
  };
});

const CorporateProfilePage = (props) => {
  const { title } = props;
  const classes = useStyles();

  const { isMd } = useMediaQueries();

  return (
    <>
      <Grid container spacing={ isMd ? 4 : 2 }>
        <Grid item xs={ 12 }>
          <div
            className={ classes.titleCta }
            style={ { display: 'flex', justifyContent: 'space-between' } }
          >

            <Typography variant='h6' color='textPrimary'>
              {title}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={ 12 }>
          <Divider />
        </Grid>
        <Grid container item xs={ 12 } md={ 10 }>
          <Grid item xs={ 12 }>
            <CorporateDetailsForm />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CorporateProfilePage;
