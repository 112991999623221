import pino from 'pino';

const detectOS = () => {
  const { userAgent } = window.navigator;

  if (/Windows/.test(userAgent)) {
    return 'Windows';
  } if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
    return 'MacOS';
  } if (/Linux/.test(userAgent)) {
    return 'Linux';
  } if (/Android/.test(userAgent)) {
    return 'Android';
  } if (/iOS|iPhone|iPad|iPod/.test(userAgent)) {
    return 'iOS';
  }
  return 'Unknown OS';
};

const getScreenResolution = () => {
  return {
    width: window.screen.width,
    height: window.screen.height,
  };
};

const sayswho = () => {
  const ua = navigator.userAgent;
  let tem;
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return `IE ${ tem[1] || '' }`;
  }
  if ('Chrome' === M[1]) {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (null != tem) { return tem.slice(1).join(' ').replace('OPR', 'Opera'); }
  }
  M = M[2] ? [ M[1], M[2] ] : [ navigator.appName, navigator.appVersion, '-?' ];
  // eslint-disable-next-line no-cond-assign
  if (null != (tem = ua.match(/version\/(\d+)/i))) { M.splice(1, 1, tem[1]); }
  return M.join(' ');
};

const send = async function (level, logEvent) {
  const url = `${ process.env.REACT_APP_LOGGER_URL }logstream/test-logs`;

  const email = localStorage.getItem('email');
  const userGroup = localStorage.getItem('userGroup');

  const body = JSON.stringify({
    messages:
    logEvent.messages.map((le) => {
      return {
        url: window.location.href,
        browser: sayswho(),
        os: detectOS(),
        resolution: getScreenResolution(),
        email,
        userGroup: userGroup?.replace('"', '')?.replace('"', ''),
        message: le,
        level: logEvent?.level?.label,
      };
    }),
  });

  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${ process.env.REACT_APP_LOGGER_CREDENTIALS }`,
      'Content-Type': 'application/json',
    },
    body,
  });
};

const logger = pino({
  browser: {
    serialize: true,
    asObject: true,
    transmit: {
      send,
    },
  },
});

export default logger;
