import React from 'react';
// import { useTranslation } from 'react-i18next';
import { Grid, List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import { useDatetimeFormat } from 'utils';
import clsx from 'clsx';
import { useAuth } from 'AuthCtx';
import AppPagination from 'components/app/AppPagination';
import { useTranslation } from 'react-i18next';
import { useItems } from '../../../models/atoms/items-hook';
import CollectionKeys from '../../../models/atoms/collection-keys';
// import AppTable from '../../../components/app/AppTable';
/*
const DoctorsTable = () => {
  const { t } = useTranslation();

  const tableColumns = [
    { id: 1, label: t('serialNumber'), field: 'registration_kit.serial_number_id.serial' },
    { id: 2, label: t('header-name'), field: 'doctor.name' },
    { id: 3, label: t('header-surname'), field: 'doctor.family_name' },
    { id: 4, label: t('email'), field: 'doctor.email' },
    { id: 5,
      label: '',
      sortable: false,
      field: 'created_at',
      customRenderer() {
        return null;
      } },
  ];

  const [ search, setSearch ] = useState('');

  const filterQuery = useMemo(() => {
    const trimmedSearch = search.trim();

    if ('' === trimmedSearch) {
      return {};
    }

    const searchTokens = trimmedSearch
      .split(' ')
      .map((tt) => { return tt.trim(); })
      .filter((s) => { return '' !== s; });

    if (0 === searchTokens.length) {
      return {};
    }

    if (1 === searchTokens.length) {
      const [ f ] = searchTokens;
      return {
        _where: {
          _or: [
            [{ 'registration_kit.serial_number_id.serial_contains': f }],
            [{ 'doctor.name_contains': f }],
            [{ 'doctor.family_name_contains': f }],
            [{ 'doctor.email_contains': f }],
          ],
        },
      };
    }

    if (2 === searchTokens.length) {
      // assume the user has written firstName and lastName
      const [ f, l ] = searchTokens;
      return {
        _where: {
          _or: [
            [{ 'doctor.name_contains': f, 'doctor.family_name_contains': l }],
            [{ 'doctor.name_contains': l, 'doctor.family_name_contains': f }],
          ],
        },
      };
    }

    return {
      _limit: 0,
    };
  }, [ search ]);

  const {
    items: something,
    itemsLoading: somethingLoading,
    itemsError: somethingError,
    pagination,
  } = useItems(CollectionKeys.CustomerDoctorKits,
    {
      defaultPagination: { limit: 5, sort: { order: 'desc', orderBy: 'created_at' } },
      filterQuery,
    });

  console.log('something: ', something);

  if (somethingError) {
    return <p>Failed to load doctors</p>;
  }

  return (
    <AppTable
      columns={ tableColumns }
      paginationProps={ pagination }
      loading={ somethingLoading }
      data={ something }
      searchProps={ {
        search,
        setSearch,
      } }
    />
  );
}; */

const useStyles = makeStyles((theme) => {
  return {
    titleCta: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    list: {
      width: '100%',
    },
    listItem: {
      marginBottom: '1px',
    },
    listItemOdd: {
      backgroundColor: theme.palette.alternate.light,
    },
    listItemEven: {
      backgroundColor: 'white',
    },
    listItemOddActive: {
      backgroundColor: '#ffeee0',
    },
    listItemEvenActive: {
      backgroundColor: '#ffe5ce',
    },
    listItemOddActiveCorporate: {
      backgroundColor: '#EEB3BA',
    },
    listItemEvenActiveCorporate: {
      backgroundColor: '#F6D5D8',
    },
  };
});

const InvitationItem = ({ invitation, i, isCorporate = false }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const dateFormat = useDatetimeFormat();

  let reason = '';
  if ('healthcare-professional-share-results' === invitation.reason) {
    reason = t('observation');
  } else if ('pgx-doctor-consent' === invitation.reason) {
    reason = t('consent');
  }

  return (

    <ListItem
      className={
        clsx(
          classes.listItem,
          i % 2 ? (
            invitation.read ? classes.listItemEven : isCorporate
              ? classes.listItemEvenActiveCorporate : classes.listItemEvenActive
          ) : (invitation.read ? classes.listItemOdd : isCorporate
            ? classes.listItemOddActiveCorporate : classes.listItemOddActive),
        )
      }
    >
      <ListItemText
        primary={ `${ t('doctorHealthProfessional') }: ${ invitation.metadata.firstName } ${ invitation.metadata.lastName } ${ invitation.metadata.profession ? `(${ invitation.metadata.profession })` : '' }` }
        secondary={ `${ dateFormat(invitation.created_at) } - ${ reason } ${ t('for serial number') } ${ invitation.registration_kit?.serial_number_id?.serial ? invitation.registration_kit?.serial_number_id?.serial : '-' } ` }
      />

    </ListItem>
  );
};

export const DoctorInvitations = () => {
  const {
    items: invitations,
    isLoading: invitationsLoading,
    pagination,
  } = useItems(CollectionKeys.Invitations, {
    filterQuery: {
      status_eq: [ 'accept' ],
    },
  });

  const { isCorporate } = useAuth();

  if (invitationsLoading) {
    return <p>Loading</p>;
  }

  return (
    <>
      <Grid container>
        <Grid item>
          <AppPagination
            page={ pagination.page }
            setPage={ pagination.setPage }
            count={ pagination.count }
            limit={ pagination.limit }
          />
        </Grid>
      </Grid>
      <Grid item xs={ 12 }>
        <List>
          {
            invitations?.map((invitation, i) => {
              return (
                <InvitationItem
                  key={ invitation.id }
                  invitation={ invitation }
                  i={ i }
                  isCorporate={ isCorporate }
                />
              );
            })
          }
        </List>
      </Grid>
    </>
  );
};
