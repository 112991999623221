import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Dialog from '@material-ui/core/Dialog';
import React, { useState } from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import { Button, Container, makeStyles, Divider, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import clsx from 'clsx';
import { useAuth } from 'AuthCtx';
import useAPI from 'hooks/api-hooks';
import { useQueryClient } from 'react-query';
import CollectionKeys from 'models/atoms/collection-keys';
import { corporateTheme } from 'theme/palette';
import { parseDate, useDatetimeFormat } from '../utils';
import { LoadingButtonNoGrid } from './forms/pgx-forms/EntitlePersonalResultsForm';

const useStyles = makeStyles((theme) => {
  return {
    list: {
      width: '100%',
      paddingTop: 0,
    },
    listItem: {
      marginBottom: '1px',
      fontFamily: 'Roboto',
      paddingRight: 0,
      display: 'flex',
      flexWrap: 'wrap',
    },
    listItemOdd: {
      backgroundColor: theme.palette.alternate.light,
      fontFamily: 'Roboto',
    },
    listItemEven: {
      backgroundColor: 'white',
      fontFamily: 'Roboto',
    },
    resumeRegistrationButton: {
      color: 'white',
    },
  };
});

export default function KitsList(
  {
    kits,
    onClick,
    refetch,
  },
) {
  const classes = useStyles();

  const { t } = useTranslation();

  const { isDoctor, isCorporate } = useAuth();

  const dateFormat = useDatetimeFormat();

  const [ toBeDeleted, setToBeDeleted ] = useState(null);
  const [ deleteConfirmOpen, setDeleteConfirmOpen ] = useState(false);
  const [ deleteLoading, setDeleteLoading ] = useState(false);
  const queryClient = useQueryClient();

  const { client } = useAPI();

  const deleteKit = () => {
    if (null != toBeDeleted) {
      setDeleteLoading(true);
      client.delete(`registration-kits/${ toBeDeleted }`).finally(() => {
        queryClient.removeQueries([ CollectionKeys.DoctorSortedKits ]);
        refetch().finally(() => {
          setDeleteLoading(false);
          setToBeDeleted(null);
          setDeleteConfirmOpen(false);
        });
      });
    } else {
      console.error('No kit selected');
    }
  };

  if (!kits || !kits.length) {
    return null;
  }

  return (
    <Container style={ { marginTop: isDoctor ? '0' : '0' } }>
      <Dialog
        open={ deleteConfirmOpen }
        onClose={ () => { setDeleteConfirmOpen(false); } }
        fullWidth
        maxWidth='sm'
      >
        <DialogContent>
          <Typography>
            {t('Είστε σίγουρος ότι θέλετε να διαγράψετε το κιτ;')}
          </Typography>

          <DialogActions>
            <LoadingButtonNoGrid
              loading={ deleteLoading }
              onClick={ () => { deleteKit(); } }
              label={ t('yes') }
              variant='contained'
              fullWidth={ false }
              color='secondary'
              style={ { color: 'white' } }
            />

            <Button
              variant='contained'
              style={ { background: 'red', color: 'white' } }
              onClick={ () => { setDeleteConfirmOpen(false); } }
            >
              {t('no')}
            </Button>
          </DialogActions>
        </DialogContent>

      </Dialog>

      <Alert severity='warning'>
        <AlertTitle>
          {t('Complete Kit Registration')}
        </AlertTitle>
        {t('You have kits that are pending registration')}
      </Alert>
      <AlertTitle
        style={ { textAlign: 'initial',
          marginTop: '20px',
          paddingLeft: '17px',
          fontSize: '20px',
          color: isDoctor ? '#648DAE' : isCorporate ? corporateTheme.primary.main : '#93C221',
          fontFamily: 'Roboto',
          lineHeight: '21px',
          letterSpacing: '.04em' } }
      >
        {t('resumeRegistration')}
        <Divider style={ { width: '70%', marginTop: '4px' } } />
      </AlertTitle>
      <List className={ classes.list }>
        {
          kits.map((kit, i) => {
            const isRepeat = null !== kit?.serial_number_id?.ancestor;
            return (
              <React.Fragment key={ kit.id }>
                <ListItem
                  key={ kit.id }
                  className={
                    clsx(classes.listItem, i % 2 ? classes.listItemEven : classes.listItemOdd)
                  }
                >
                  <ListItemText
                    primary={ `${ t('serialNumber') }: ${ isRepeat ? kit.serial_number_id.serial?.split('_')[0] : kit.serial_number_id.serial }${ isRepeat ? ` (${ t('DynamicMonitoring') }: ${ parseDate(kit.created_at) })` : '' }` }
                    secondary={ (
                      <>
                        {
                          isDoctor && null != kit.customer_id?.name && null != kit.customer_id?.family_name && null != kit.customer_id?.email && (
                            <>
                              <div>
                                {`${ t('header-name') }: ${ kit.customer_id?.name } ${ kit.customer_id?.family_name }` }
                              </div>

                              <div>
                                {`${ t('email') }: ${ kit.customer_id?.email }` }
                              </div>
                            </>
                          )
                        }
                        <div>
                          { `${ t('registeredAt') } ${ dateFormat(kit.created_at) }` }
                        </div>
                      </>
                    ) }
                  />

                  <div style={ { display: 'flex', marginLeft: '10px', flexWrap: 'wrap', gap: '10px' } }>
                    <Button
                      className={ classes.resumeRegistrationButton }
                      variant='contained'
                      color='secondary'
                      onClick={ () => { return onClick(kit); } }
                    >
                      { t('resumeKitRegistration') }
                    </Button>

                    {
                      isDoctor && (
                        <Button
                          className={ classes.resumeRegistrationButton }
                          variant='contained'
                          style={ {
                            background: 'red',
                          } }
                          onClick={ () => {
                            setToBeDeleted(kit.serial_number_id.serial);
                            setDeleteConfirmOpen(true);
                          } }
                        >
                          { t('deleteKit') }
                        </Button>
                      )
                    }

                  </div>
                </ListItem>
                <Divider light />
              </React.Fragment>
            );
          })
        }
      </List>
    </Container>
  );
}
